.workshop {
    padding-bottom: 70px;
    border-bottom: 2px solid $brand-lightgray;
    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        & > .meta .meta {
            display: inline;
            margin-right: 50px;
        }
        & > .meta .meta:last-child {
            margin-right: 0;
        }
        .eyebrow {
            font-weight: 900;
        }
    }
    h3 {
        margin-bottom: $gutter * .5;
    }
    & > p {
        margin-bottom: $gutter;
    }
    @include media-query-blue {
        header {
            flex-direction: column;
        }

    }
}