.compact-testimonial {
    background: $brand-lightgray;
    padding: 50px 0;
    blockquote {
        margin: 0;
        font-family: $font-serif;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 20px;
    }
    span {
        display: block;
        font-style: initial;
    }
    .name {
        font-weight: 800;
    }
    .meta {
        font-weight: 300;
        color: #000;
        font-size: 16px;
    }
}