/// Homepage 

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to { 
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.home {
    position: relative;
    .follow-graphic {
        width: 219px;
        position: absolute;
        z-index: 0;
        top: 180px;
        right: 176px;
        transition: top 2000ms, right 2000ms, transform 2000ms, width 2000ms;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: rotate(0deg);
        .cls-1 {
            transition: fill 2000ms;
        }
        &.second-state {
            -webkit-animation-name:             rotate; 
            -webkit-animation-duration:         25s; 
            -webkit-animation-iteration-count:  infinite;
            -webkit-animation-timing-function:  linear;
            animation: rotate 25s infinite linear;
        }
    }
    @media all and (-ms-high-contrast:none) {
        .follow-graphic {
            width: 220px;
            height: 230px;
            &.second-state {
                height: 340px;
            }
        }
    }
    .hero {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        clip-path: none;
        .testimonial.--large {
            margin: 0 auto;
            margin-bottom: $gutter * 3;
        }
        .hero-follow {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            z-index: -999;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, rgba(#000, .4) 30%, transparent 60%);
            }
        }
        .hero-overlay {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: -998;
            background: rgba(#000, .6);
            opacity: 0;
        }
        .testimonial.--large {
            opacity: 0;
            margin-top: 100px;
            transform: translateY(100px);
            transition: opacity 1s, transform 1s;
            margin-bottom: 40px;
        }
        .testimonial.--large.reveal {
            opacity: 1;
            transform: translateY(0px);
        }
        .hero-follow.stick,
        .hero-overlay.stick {
            position: absolute;
            bottom: 0;
        }
        .button-wrapper {
            margin-bottom: $gutter * 3;
        }
        &::after {
            display: none;
        }
        .hero-testimonial {
            position: relative;
            .button.-slider-left,
            .button.-slider-right {
                position: absolute;
                color: #fff;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2.6rem;
            }
            .button.-slider-left {
                left: 0;
            }
            .button.-slider-right {
                right: 0;
            }
            .slide-nav {
                margin-bottom: $gutter;
                button {
                    background: rgba(#fff, .7);
                    &.active {
                        background: $brand-darkblue;
                    }
                }
            }
        }
    }
    .services {
        text-align: left;
        margin-bottom: $gutter * 2;
        h2 {
            font-size: 50px;
            line-height: 1.3;
        }
        .subhead {
            margin-bottom: $gutter * 2;
        }
    }
    .map {
        padding-bottom: $gutter * 2;
        padding-top: 150px;
        box-shadow: inset 0 60px 107px -40px $brand-paleblue;
        .home-stats {
            margin-bottom: 170px;
            text-align: right;
            position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 845px;
                height: 570px;
                top: -50px;
                left: -240px;
                z-index: -1;
            }
            h2 {
                display: inline-block;
                position: relative;
                margin-bottom: 0;
                max-width: none;
                padding-left: 340px;
                &::after {
                    position: absolute;
                    right: -54px;
                    bottom: 97px;
                }
            }
            &.lazyloaded::after {
                background: url('/img/icons/map.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }
    .about {
        margin-bottom: $gutter * 3;
        padding-top: $gutter;
        cursor: pointer;    
    }
    .homepage-header-wrap {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        min-height: 700px;
        .hero-header {
            margin-top: -110px;
            h1, .subhead p {
                color: #fff;
                strong {
                    font-weight: 900;
                }
            }
        }
        .resource-ctas {
            position: absolute;
            bottom: $gutter * 2;
        }
    }
    .content-designer {
        padding-top: 0;
        h2 {
            color: black;
            font-size: 50px;
            font-weight: 100;
            font-family: $font-sans-serif;
        }
    }
    .topics, .success-stories {
        h3 {
            font-size: 35px;
            font-weight: 700;
            text-align: center;
        }
    }
    .success-stories {
        padding-top: 60px;
    }
    .success-stories .content-designer h5{
        color: black;
        font-weight: 100;
        font-size: 22px;
    }
    
    .success-stories .content-designer .basic p,
    .success-stories .content-designer .content > * {
        margin-bottom: 15px;
    }
    .contact-form {
        h2 {
            font-size: 52px;
            max-width: 100%;
        }
        div {
            font-size: 16px;
        }
    }
     /// Image with Text
    .image-with-text .columns .image {
        img {
            box-shadow: none;
        }
    }
    @include media-query-green {
        .hero {
            .testimonial.--large {
                opacity: 0;
                transform: translateY(100px);
                transition: opacity 1s, transform 1s;
            }
        }
        .homepage-header-wrap {
            padding-top: $gutter * 6;
            .hero-header {
                margin-top: 0;
                .subhead {
                    margin-bottom: $gutter * 4;
                }
            }
            .resource-ctas {
                position: relative;
                bottom: 0;
            }
        }
    }
    @include media-query-blue {
        .hero {
            .testimonial.--large,
            .testimonial.--large.reveal {
                opacity: 1;
                transform: translateY(0px);
            }
            .hero-testimonial {
                .button.-slider-left,
                .button.-slider-right {
                    display: none;
                }

            }
        }
        .homepage-header-wrap {
            height: auto;
        }
    }
    @include media-query-green {
        .follow-graphic {
            display: none;
        }
        .map {
            padding-top: $gutter * 2;
            .home-stats {
                margin-bottom: $gutter * 2;
                &::after {
                    display: none;
                }
                h2 {
                    padding-left: 0;
                }
            }
        }
    }
}