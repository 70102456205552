.testimonial-video {
    blockquote {
        margin: 0;
        padding-top: 40px;
        margin-bottom: $gutter * 2;
    }
    blockquote p {
        font-size: 24px;
        line-height: 40px;
        &::before,
        &::after {
            color: $brand-lightblue;
            font-family: $font-serif;
            font-size: 64px;
            line-height: 0;
            font-weight: 800;
        }
        &::before {
            content: '“';
            display: block;
            margin-bottom: -10px;
        }
        &::after {
            content: '”';
            vertical-align: -28px;
        }
    }
    cite {
        font-size: 18px;
        font-style: initial;
    }
    cite span {
        display: block;
    }
    cite span.name {
        font-weight: 700;
    }
}