/// Contact Form

.contact-form {
    max-width: 700px;
    h2 {
        font-family: $font-serif;
        font-size: 72px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }
    p.subhead {
        margin-bottom: $gutter * 3;
    }

    form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .text,
        .hs-fieldtype-text {
            width: calc(50% - 7px);
            margin-bottom: $gutter;
        }
        .textarea,
        .hs-fieldtype-textarea {
            margin-bottom: $gutter * 1.5;
            width: 100%;
        }
        .button-wrapper {
            width: 100%;
            text-align: center;
        }
        .hs-submit {
            margin: auto;
            width: 100%;
        }
    }

    .hs_email {
        width: 100% !important;
    }

    .form-columns-1,
    .form-columns-2 {
        width: 100%;
        max-width: none;
    }

    .hs-input {
        width: 100% !important;
    }

    &.--dark {
        background: $brand-darkblue;
    }
    @include media-query-purple {
        form {
            .text,
            .hs-fieldtype-text {
                width: 100% !important;
            }
        }
    }
}
