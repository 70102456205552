/// Text Area

.textarea,
.hs-fieldtype-textarea {
    label {
        display: block;
        font-family: $font-sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: $gutter * .5;
        span.required {
            margin-left: 5px;
            color: #fff;
        }
    }
    textarea {
        background: #fff;
        font-size: 18px;
        padding: 19px 26px;
        border: none;
        border-radius: 5px;
        width: 100%;
        outline: none;
        line-height: 1.7;
        transition: border 125ms, background 125ms;
        font-family: $font-sans-serif;
        border: 2px solid $brand-lightgray;
        &:focus {
            border: 2px solid $brand-skyblue;
        }
    }
    input::placeholder {
        color: $brand-gray;
    }

    // Defaults
    label {
        color: $brand-darkblue;
    }

    // Light Text Input
    &.--light {
        label {
            color: $brand-yellow;
        }
        textarea::placeholder {
            color: $brand-gray;
        }
        textarea:focus {
            background: $brand-paleblue;
        }
    }

    // Dark Text Input
    &.--dark {
        textarea {
            border: 2px solid $brand-paleblue;
        }
        textarea:focus {
            border: 2px solid $brand-skyblue;
        }
    }
}