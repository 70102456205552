/// TOC
///
/// 1. Color
/// 2. Fonts
/// 3. Spacing/Structure
/// 

/* 1. Color */

// Primary
$brand-darkblue: #283C64;
$brand-lightblue: #2DB9E1;
$brand-button-hover: #304a79;

$brand-text: #333333;

// Secondary
$brand-green: #0FAF91;
$brand-darkgreen: #115855;
$brand-purple: #964690;
$brand-darkpurple: #583F6C;
$brand-orange: #F56E1E;
$brand-darkorange: #7B342D;
$brand-yellow: #FFC332;
$brand-darkerblue: #0a1427;
$brand-skyblue: #7FB4E1;
$brand-paleblue: #F0F5FA;

$brand-gray: #828282;
$brand-lightgray: #E4E2DC;
$brand-darkgray: #484848;

//Media Query Colors
$mq-red: #fe0002;
$mq-yellow: #ffff01;
$mq-green: #3cb54c;
$mq-blue: #003470;
$mq-purple: #440e62;
$mq-black: #000;

/* 2. Fonts */
$font-serif: 'Volkhov', Georgia, serif;
$font-sans-serif: 'Avenir', Arial, sans-serif;

/* 3. Spacing/Structure */
$gutter: 30px;
$container: 1200px;