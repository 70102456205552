// Mobile Navigation
nav.mobile-navigation {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 250ms, transform 250ms;
    flex-direction: column;
    button.-mobile-close {
        position: absolute;
        top: 11px;
        right: 33px;
    }
    button.nav-link.-back {
        position: absolute;
        top: 13px;
        right: 83px;
        display: none;
        &:hover {
            color: $brand-darkblue;
        }
    }
    .m-nav-header {
        padding-left: 30px;
        padding-top: 48px;
        padding-bottom: 0;
        .logo {
            width: 106px;
        }
    }
    .m-nav-footer {
        padding: 25px 25px;
        padding-bottom: 0;
        margin-top: auto;
        border-top: 2px solid $brand-paleblue;
        ul {
            margin: 0;
            margin-bottom: $gutter;
            padding-left: 0;
        }
        ul li {
            margin-bottom: 25px;
            line-height: 1;
            .fa-search {
                margin-right: 10px;
            }
            &::before {
                display: none;
            }
        }
        .fineprint {
            text-align: center;
            margin-bottom: $gutter;
            font-size: 8px;
        }
        .nav-link {
            width: 100%;
            text-transform: uppercase;
            position: relative;
            display: block;
        }
    }
    .m-nav-panel {
        &.-sub,
        &.-primary {
            display: none;
            padding: $gutter * .5;
            .eyebrow {
                margin-bottom: $gutter * .5;
            }
            .subnav-link {
                margin-bottom: 10px;
            }
            .subnav-link p {
                line-height: 1.24;
            }
            .subnav-link .icon {
                width: 60px;
                min-width: 60px;
            }
            &.opened {
                display: block;
            }
        }
        &.-primary {
            display: block;
            &.closed {
                display: none;
            }
        }
    }

    // Opened
    &.opened {
        display: flex;
    }

    // Revealed
    &.revealed {
        opacity: 1;
        transform: scale(1);
    }
}