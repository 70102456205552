/// Primary Header
header.primary {
    position: fixed;
    width: 100vw;
    background: #fff;
    z-index: 999;
    height: 120px;
    top: 0;
    transition: height 250ms, box-shadow 250ms;
    box-shadow: 0px 10px 20px rgba(#000, 0);
    .header-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        .brand {
            padding-top: 16px;
            padding-bottom: 22px;
            transition: padding 250ms;
            width: 206px;
            position: relative;
            z-index: 1;
            .logo-stacked {
                transition: margin-top 250ms, width 250ms;
                margin-top: 0;
                width: 100%;
                .letter {
                    transition: fill 250ms;
                    fill: $brand-darkblue !important;
                }
            }
        }

        .navigation {
            max-width: 800px;
            width: 100%;
            padding-top: 16px;
            transition: padding 250ms;

            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 1fr 1fr;

            nav.utlity {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }
            nav.primary {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }

            nav.utility ul {
                justify-content: flex-end;
            }
            nav.utility {
                margin-bottom: 20px;
                height: 27px;
                transition: height 250ms, margin-bottom 250ms, opacity 250ms;
                opacity: 1;
                pointer-events: auto;
            }
            button.button.-mobile-trigger {
                display: none;
            }
        }
    }
    @media all and (-ms-high-contrast: none) {
        .header-wrap {
            .brand {
                .logo-stacked {
                    height: 83.5px;
                }
            }
        }
    }
    @include media-query-green {
        height: 59px;
        nav.primary {
            display: none;
        }
        nav.utility {
            order: 0;
        }
        .header-wrap {
            .brand {
                padding-top: 9px;
                .logo-stacked {
                    width: 102px;
                }
            }
            .navigation {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-end;
                button.button.-mobile-trigger {
                    display: block;
                }
            }
        }
        &::after {
            display: none !important;
        }
    }
    @include media-query-blue {
        nav.utility {
            display: none;
        }
    }
}

// Styles for condensing on scroll
body.scrolled {
    header.primary {
        height: 75px;
        box-shadow: 0px 5px 20px rgba(#000, 0.3);
        .header-wrap {
            .brand {
                padding-top: 0;
                padding-bottom: 0;
                .logo-stacked {
                    margin-top: 15px;
                    width: 78%;
                    .letter {
                        fill: transparent !important;
                    }
                }
            }
            .navigation {
                padding-top: 8px;
                nav.utility {
                    height: 32px;
                    margin-bottom: 0;
                }

                // .login-button a {
                //     padding: 0.2rem .7rem;
                // }
                @media only screen and (min-width: 1030px) {
                    .login-button a {
                        padding: 0.15rem .5rem;
                    }
                }
                .login-button p {
                    line-height: 1;
                    font-size: 14px;
                }
            }
        }
    }
    @media all and (-ms-high-contrast: none) {
        header.primary {
            .header-wrap {
                .brand {
                    .logo-stacked {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    header.primary.hover-indicate::after {
        opacity: 0.35;
        transform: translateY(5px);
    }
    @include media-query-green {
        header.primary {
            height: 59px;
            .header-wrap {
                .navigation {
                    padding-top: 16px;
                    nav.utility {
                        height: 27px;
                        order: 0;
                        opacity: 1;
                    }
                }
                .brand {
                    padding-top: 9px;
                }
                .brand .logo-stacked {
                    margin-top: 0;
                    width: 102px;
                    .letter {
                        fill: $brand-darkblue !important;
                    }
                }
            }
        }
    }
}
