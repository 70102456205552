section.packages{
    display: block;
    @include media-query-green {
        display: none;
    }
}

section.packages-mobile {
    display: none;
    margin-top: $gutter;
    @include media-query-green {
        display: block;
    }
}