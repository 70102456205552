.jump-nav {
    background: $brand-paleblue;
    padding: 20px 0;
    z-index: 5;
    .container {
        display: flex;
        flex-direction: row;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        padding: 0 $gutter;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        flex-wrap: wrap;
        flex-grow: 1;
        li {
            margin-bottom: 0;
            font-weight: 700;
            color: $brand-darkblue;
            transition: color 250ms;
            cursor: pointer;
            &::before {
                display: none;
            }
        }
        li.active {
            color: $brand-green;
        }
    }
    .page-title {
        font-weight: 800;
        color: $brand-lightblue;
        padding-right: 0;
        overflow: hidden;
        max-width: 0;
        white-space: nowrap;
        border-right: 2px solid transparent;
        transition: border-right 250ms, max-width 250ms, padding-right 250ms;
    }
    &.stick {
        position: fixed;
        top: 69px;
        width: 100%;
        margin-top: 0;
        .page-title {
            max-width: 302px;
            border-right: 2px solid $brand-darkblue;
            padding-right: $gutter;
        }
    }
    @include media-query-purple {
        margin-bottom: 50px;
        ul {
            flex-direction: column;
            li {
                width: 100% !important;
            }
        }
        &.stick {
            position: relative;
        }
    }
    @include media-query-blue {
        padding-bottom: 10px;
        ul {
            li {
                width: 50%;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }
    @include media-query-green {
        &.stick {
            top: 59px;
            .page-title {
                display: none;
            }
        }
    }
}