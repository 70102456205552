.title-indicator {
    display: none;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 10;
    background: $brand-paleblue;
    padding: 20px 0;
    transition: top 250ms;
    .eyebrow::before {
        @include fa-icon;
        @extend .fas;
        content: fa-content($fa-var-school);
        color: $brand-yellow;
        font-weight: 600;
        vertical-align: 2px;
        font-size: 18px;
        margin-right: 13px;
    }
}

@media screen and (min-width: 1024px) {
    .title-indicator {
        display: block;
    }
}

@media screen and (min-width: 1280px) {
    .title-indicator {
        top: 113px;
    }
}

// body.scrolled .title-indicator {
//     top: 68px;    
//     @include media-query-green {
//         top: 59px;
//     }
// }