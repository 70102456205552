body.blog-list {
    .wrapper {
        position: relative;
        min-height: 500px;
    }

    .hero-footer {
        margin-bottom: 10px;
    }

    .hide-overlay {
        content: '';
        display: flex;
        position: absolute;
        background: #fff;
        top: -20px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 30px);
        opacity: 0;
        pointer-events: none;
        transition: opacity 250ms;
        z-index: 1;
        justify-content: center;
        align-items: flex-start;
        img {
            animation: spin 4s linear infinite;
            width: 100px;
            margin-top: 100px;
        }
    }
    .hide-overlay.active {
        pointer-events: auto;
        opacity: 1;
    }
}

body.blog {
    main > .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .entry-meta {
        max-width: 320px;
        min-width: 320px;
        width: 100%;
    }
    .content-designer {
        flex-grow: 1;
        width: 100%;
        margin-right: 60px;
        padding-top: 0;
        .image-with-text .columns .image {
            min-width: 50%;
        }
        .testimonial.--large blockquote p {
            font-size: 25px;
        }
        .testimonial blockquote {
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        .testimonial.--large cite {
            font-size: 16px;
        }
        .cd-cta {
            padding: 50px 30px;
        }
        .featured-resource {
            padding: 30px;
        }
        .featured-resource .featured-resource-row {
            flex-direction: column;
        }
        .featured-resource .image::after {
            display: none;
        }
        .featured-resource .image {
            text-align: left;
            margin: 0;
            margin-bottom: 2em;
            padding: 0;
        }
        .featured-resource .image,
        .featured-resource .info {
            width: 100%;
        }
        .featured-resource .info {
            padding: 0;
        }
    }
    @include media-query-blue {
        main > .container {
            flex-direction: column;
        }
        .content-designer > * {
            margin-left: -30px;
            margin-right: -30px;
        }
        .entry-meta {
            text-align: left;
            max-width: none;
            margin-left: 0;
            margin-bottom: 30px;
        }
        .entry-meta .blog-avatar {
            margin-left: 0;
            margin-right: auto;
        }
        .content-designer {
            margin-right: 0;
            .cd-cta {
                padding: 50px 15px;
            }
            .featured-resource {
                padding: 30px 0;
            }
        }
    }
}