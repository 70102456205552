// Default Styles for Unordered lists.

ul {
    padding-left: 40px;

    li {
        margin-bottom: $gutter;
        position: relative;
        color: $brand-text;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -40px;
            top: 10px;
            width: 22px;
            height: 1px;
            z-index: 4;
            border-top: 4px solid $brand-yellow;
        }
    }
}