// Block Level Text

p,
div {
    font-size: 18px;
    font-family: $font-sans-serif;
    line-height: 1.54;
    font-weight: 400;
    color: $brand-text;
    margin: 0;

    strong {
        font-weight: 700;
    }

    // CTA Style
    &.--cta,
    &.--cta p {
        font-size: 20px;
        font-family: $font-serif;
        color: $brand-darkblue;
    }

    // Callout Style
    &.--callout,
    &.--callout p,
    h4.--callout {
        font-size: 30px;
        font-weight: 700;
        font-family: $font-serif;
        color: $brand-darkblue;
    }

    h4.--callout {
        margin-top: 0;
    }

    // Intro Style
    &.--intro,
    &.--intro p {
        font-size: 24px;
        font-weight: 700;
        font-family: $font-serif;
        color: $brand-darkblue;
    }

    &.--service,
    &.--service p {
        margin-bottom: 15px;
        margin-top: 5px;
        font-size: 20px;
        font-weight: 700;
    }

}