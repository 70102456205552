.category {
    padding-top: 70px;
    padding-left: 100px;
    position: relative;
    z-index: 2;
    & > h3 {
        color: $brand-orange;
        font-family: $font-sans-serif;
        font-weight: 800;
        font-size: 28px;
        text-transform: uppercase;
        margin-bottom: 35px;
        z-index: 3;
        position: relative;
    }
    > .workshop {
        margin-bottom: 70px;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 72vw;
        height: 60px;
        z-index: 0;
        box-shadow: inset 1px 54px 109px -123px rgba(#000, .5);
        border-bottom-left-radius: 100%;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 75vw;
        height: 130px;
        z-index: 1;
        border-bottom-left-radius: 100%;
        box-shadow: inset 107px -97px 79px -76px #fff;
    }
    @include media-query-green {
        &::before {
            width: 100vw;
        }
        &::after {
            width: 100vw;
        }
    }
}