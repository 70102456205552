// Select 

.select {
    position: relative;

    label {
        display: block;
        font-family: $font-sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: $gutter * .5;
        color: $brand-text;
        span.required {
            margin-left: 5px;
        }
    }

    // Resets
    select {
        border:none;
        background-image:none;
        background-color:transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -moz-appearance: window;
        -webkit-appearance: none;
        padding: 19px 26px;
        outline: none;
        width: calc(100% - 7px);
    }

    // Arrow
    &::after {
        @include fa-icon;
        @extend .far;
        content: fa-content($fa-var-angle-down);
        position: absolute;
        color: $brand-green;
        bottom: 0;
        right: 0;
        font-size: 1.6rem;
        bottom: 14px;
        right: 26px;
        pointer-events: none;
    }

    select::-ms-expand {
        display: none;
    }

    // Defaults
    label {
        color: $brand-darkblue;
    }
    span {
        padding: 19px 26px;
        border: 2px solid $brand-paleblue;
        border-radius: 5px;
        width: 100%;
    }


    // Light Text Select
    &.--light {
        label {
            color: $brand-paleblue;
        }    
        select {
            background: #fff;
        }
        span {
            padding: 19px 26px;
            border: none;
        }
    }

    // Dark Text Select
    &.--dark {
        label {
            color: $brand-darkblue;
        }
        span {
            padding: 19px 0;
            border: 2px solid $brand-lightgray;
        }
    }
}