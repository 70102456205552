.radio {
    input[type="radio"] {
        display: none;
    }
    &.active {
        span {
            background: rgba($brand-yellow, .25);
        }
    }
}

