.simple-dropdown {
    background: #fff;
    position: absolute;
    width: 200px;
    left: -9999px;
    right: -9999px;
    top: 50px;
    margin: auto;
    padding: 35px;
    box-shadow: 0px -1px 20px rgba($brand-darkblue, .2);
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    .arrow {
        position: absolute;
        top: -14px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        transition: right 250ms;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 14px solid #fff;
        filter: drop-shadow(0px -7px 5px rgba($brand-darkblue, .1));
    }
    .hitbox {
        width: 129px;
        height: 38px;
        position: absolute;
        top: -27px;
    }
    ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        li {
            text-align: center;
            margin-left: 0;
            margin-bottom: 13px;
            a {
                font-size: 16px;
                font-weight: 600;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                display: none;
            }
        }
    }
}

li.about:hover {
    .simple-dropdown {
        opacity: 1;
        pointer-events: auto;
    }
}
.home li.about {
    padding-top: 0;
    margin-bottom: 0;
}
body.scrolled {
    .simple-dropdown {
        top: 42px;
    }
}
