/// Large Testimonial

.testimonial {
    margin: 0 auto;
    
    blockquote {
        margin: 0;
        margin-bottom: 40px;
    }
    blockquote p {
        line-height: 1.34;
        margin: 0;
    }
    cite {
        font-style: normal;
        font-family: $font-sans-serif;
        margin: 0 auto;
        font-weight: 300;
    }

    /* Defaults */
    max-width: 750px;
    text-align: center;
    blockquote p,
    cite {
        color: $brand-darkblue;
    }
    blockquote p {
        font-size: 30px;
        &::before {
            content: '“';
            display: inline;
            color: $brand-lightblue;
            font-family: $font-serif;
            font-size: 70px;
            line-height: 1px;
            vertical-align: -30px;
            font-weight: 800;
            margin-right: 10px;
        }
        &::after {
            content: '”';
            display: inline;
            color: $brand-lightblue;
            font-family: $font-serif;
            font-size: 70px;
            line-height: 1px;
            vertical-align: -30px;
            font-weight: 800;
            margin-left: 10px;
        }
    }
    cite {
        font-size: 20px;
        span {
            display: block;
        }
        .name {
            font-weight: 800;
        }
        .meta {
            font-size: 18px;
            color: #fff;
            letter-spacing: initial;
            font-weight: 300;
        }
    }


    // Large
    &.--large {
        max-width: 750px;
        padding-top: 85px;
        text-align: center;
        blockquote p {
            font-size: 30px;
        }
        cite {
            font-size: 20px;
        }
    }

    // Small 
    &.--small {
        text-align: right;
        blockquote {
            text-align: left;
            display: flex;
            flex-direction: row;
            margin-bottom: 11px;
            &::before {
                content: '“';
                display: inline-block;
                color: $brand-lightblue;
                font-family: $font-serif;
                font-size: 50px;
                line-height: 57px;
                font-weight: 800;
                margin-right: 10px;
            }
        }
        blockquote p {
            font-size: 22px;
            &::after {
                font-size: 50px;
                vertical-align: -21px;
            }
            &::before {
                display: none;
            }
        }
        cite {
            font-size: 16px;
            width: 100%;
        }
    }

    // Light Color Scheme
    &.--light {
        blockquote p,
        cite {
            color: $brand-paleblue;
        }
    }

    // Dark Color Scheme
    &.--dark {
        blockquote p,
        cite {
            color: $brand-darkblue;
        }
    }

    @include media-query-blue {
        padding: 0 15px;
        // Large
        &.--large {
            blockquote p {
                font-size: 25px;
            }
        }

    }
}