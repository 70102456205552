.mobile-package {
    box-shadow: 0px 0px $gutter rgba($brand-darkblue, .2);
    border-radius: 5px;
    padding: $gutter * .75;
    text-align: center;
    margin-bottom: $gutter;
    h4 {
        margin-bottom: $gutter * .5;
    }
    p {
        font-weight: 900;
        margin-bottom: 10px;
    }
    p:last-child {
        margin-bottom: 0;
    }
    span {
        color: $brand-green;
    }
}