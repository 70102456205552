/// Content Designer
.content-designer {
    padding-top: $gutter * 2;
    .basic p {
        margin-bottom: $gutter;
    }
    .basic {
        img {
            max-width: 100%;
        }
    }
    .basic {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 25px;
        }

    }
    .featured-resource h3 {
        margin-top: 0;
    }
    
    h2.--feature {
        margin-top: 0;
    }

    .testimonial-parent {
        .testimonial {
            padding-top: $gutter * 2;
            padding-bottom: $gutter * 2;
        }
        &.--dark {
            background: $brand-darkblue;
            blockquote p,
            cite {
                color: $brand-paleblue;
            }
        }
        &.--light {
            background: #fff;
            blockquote p,
            cite {
                color: $brand-darkblue;
            }
        }
    }

    h2 {
        line-height: 1.2;
        margin-bottom: 25px;
        margin-top: 0;
    }

    h4 {
        font-family: $font-sans-serif;
        color: $brand-orange;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 10px !important;
    }

    h5 {
        font-family: $font-sans-serif;
        color: $brand-gray;
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px !important;
    }

    .programs {
        .category h3 {
            margin-top: 0;
            margin-bottom: 35px;
        }
        .workshop h3 {
            margin-bottom: 15px;
        }
    }

    & > * {
        &.paddingTop {
            padding-top: $gutter * 2;
            padding-bottom: 0;
        }
        &.paddingBottom {
            padding-top: 0;
            padding-bottom: $gutter * 2;
        }
        &.paddingTopAndBottom {
            padding-top: $gutter * 2;
            padding-bottom: $gutter * 2;
        }
        &.paddingNone {
            padding-top: 0;
            padding-bottom: 0;
        }
        &.white {
            background-color: white;
        }
        &.lightBlue {
            background-color: $brand-paleblue;
        }
    }
    .featured-links {
        display: flex;
        flex-direction: column;
    }
    .link-arrow {
        color: $brand-lightblue;
        margin-bottom: 10px;

        &::before {
            @include fa-icon;
            @extend .far;
            content: fa-content($fa-var-arrow-right);
            margin-right: 5px;
            vertical-align: -.025em;
            font-size: 1.4rem;
            transition: transform 125ms;
        }
        &:hover {
            color: #5ac8e7;
            &::after {
                transform: translateX(5px);
            }
        }
    }
}