/// Trending Cards

.item-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.--spaced {
        padding-top: 4em;
    }

    & > h2 {
        width: 100%;
        margin-bottom: $gutter;
    }

    &.--events {
        justify-content: flex-start;
    }
    
    .item-card {
        width: calc(33.3333% - 30px);
        margin-right: $gutter * 1.5;
        margin-bottom: $gutter * 1.5;
    }

    .item-card.--event,
    .item-card.--event:nth-child(3n) {
        margin: 0 15px;
        margin-bottom: $gutter;
        &.--emphasize {
            width: calc(66.666% - 23px);
            margin: 0 10px;
            margin-bottom: $gutter;
        }
    }

    .item-card.--team {
        width: calc(33.3333% - 20px);
        margin-right: $gutter;
        margin-bottom: 85px;
    }
    .item-card.--ctacard {
        width: calc(50% - 15px);
        margin-right: $gutter;
        margin-bottom: 55px;
    }
    .item-card.--ctacard:nth-child(3n) {
        margin-right: $gutter;
    }
    .item-card.--ctacard:nth-child(2n),
    .item-card.--ctacard:nth-child(6n) {
        margin-right: 0;
    }
    .item-card:nth-child(3n),
    .item-card.--team:nth-child(3n) {
        margin-right: 0;
    }

    // Narrow Modifier
    &.--narrow {
        padding: 0 ($gutter * 2);
    }

    @include media-query-green {
        padding: 0;
        .item-card {
            width: calc(50% - 22px);
            margin-bottom: 44px;
            margin-right: 44px;
        }
        .item-card.--team {
            width: calc(50% - 15px);
            margin-bottom: 44px;
            margin-right: 30px;
        }
        .item-card:nth-child(2n),
        .item-card.--team:nth-child(2n) {
            margin-right: 0;
        }
        .item-card:nth-child(3n) {
            margin-right: 44px;
        }
        .item-card.--team:nth-child(3n) {
            margin-right: 30px;
        }
        .item-card:nth-child(6n),
        .item-card.--team:nth-child(6n) {
            margin-right: 0;
        }

        .item-card.--event,
        .item-card.--event:nth-child(2n),
        .item-card.--event:nth-child(3n),
        .item-card.--event:nth-child(6n) {
            margin: 0 10px;
            margin-bottom: $gutter;
            &.--emphasize {
                margin: 0 10px;
                margin-bottom: $gutter;
                width: calc(50% - 22px);
                .item-card-header {
                    font-size: inherit;
                    margin-bottom: inherit;
                }
                .item-card-text {
                    background: #fff;
                }
                hr {
                    display: block;
                }
            }
        }
        &.--narrow {
            padding: 0;
        }
    }

    @include media-query-blue {
        .item-card,
        .item-card.--team
        .item-card.--ctacard {
            width: 100%;
        }
        .item-card.--team {
            margin-bottom: $gutter;
        }
        .item-card.--event.--emphasize {
            width: 100% !important;
        }
        .item-card.--event {
            margin: 0 !important;
            margin-bottom: $gutter !important;
        }
        .item-card:nth-child(2n),
        .item-card:nth-child(3n),
        .item-card {
            margin-right: 0;
        }
        .item-card.--team:nth-child(2n),
        .item-card.--team:nth-child(3n),
        .item-card.--team {
            margin-right: 0;
            width: 100%;
        }
        .item-card.--ctacard:nth-child(2n),
        .item-card.--ctacard:nth-child(3n),
        .item-card.--ctacard {
            margin-right: 0;
            width: 100%;
        }
    }

}