.slide-nav {
    text-align: center;
    button {
        background: $brand-gray;
        height: 10px;
        width: 10px;
        border: none;
        border-radius: 50%;
        padding: 0;
        margin-right: 15px;
        cursor: pointer;
        outline: none;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background: $brand-darkblue;
        }
    }
}