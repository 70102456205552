/// Eyebrow Tags

p.eyebrow,
.eyebrow p,
.eyebrow,
.eyebrow a {
    font-size: 13px;
    text-transform: uppercase;
    font-family: $font-sans-serif;
    font-weight: 700;
    line-height: 1;
    &.--light,
    &.--light p {
        color: $brand-lightblue;
    }
    &.--dark,
    &.--dark p {
        color: $brand-darkblue;
    }
    &.--gray,
    &.--gray p {
        color: $brand-gray;
    }
    &.--gold,
    &.--gold p {
        color: $brand-yellow;
    }
    &.--orange,
    &.--orange p {
        color: $brand-orange;
    }
    &.--white,
    &.--white p {
        color: #fff;
    }

    // Note: padding applied to make room for visual element. 
    // In many cases this may need to be overridden so that 
    // the parent can deside it's positioning context.
    &.-brand,
    &.-brand p {
        font-size: 18px;
        font-weight: 800;
        position: relative;
        display: inline-block;
        padding: 23px 0;
        margin-bottom: 0;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 45px;
            height: 63px;
            background: url('/img/patterns/eyebrowfeature.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &.-service,
    &.-service p {
        font-size: 18px;
        font-weight: 800;
    }
    &.-large,
    &.-large p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: .7px;
    }
    &.-feature,
    &.-feature p,
    &.-feature a {
        text-transform: none;
        font-size: 24px;
        font-family: $font-serif;
    }
}

