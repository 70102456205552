.topic-select {
    padding: 60px 0 70px;
    text-align: center;
    &-title {
        font-size: 1.1rem;
        margin-bottom: 30px;
    }
    .checkbox {
        border: 1px solid $brand-lightblue;
        border-radius: 50px;
        display: inline-block;
        overflow: hidden;
        margin-right: 14px;
        margin-bottom: 1rem;
        cursor: pointer;
    }
    label {
        color: $brand-darkblue;
        padding: 7px 30px;
        display: block;
        font-size: 1rem;
    }
    input {
        display: none;
    }
    input:checked + label,
    .checkbox:hover label {
        background-color: $brand-lightblue;
        color: #fff;
    }

    @include media-query-green { 
        padding: 30px 0 30px;
    }
}