@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.resource-index {
    .featured-resource {
        margin-bottom: $gutter * 2;
    }
    section.filtering .subhead {
        margin-bottom: $gutter;
    }
    .torso {
        .resources {
            padding-top: 30px;
        }
    }
    .wrapper {
        position: relative;
        min-height: 500px;
    }
    .hide-overlay {
        content: '';
        display: flex;
        position: absolute;
        background: #fff;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 30px);
        opacity: 0;
        pointer-events: none;
        transition: opacity 250ms;
        z-index: 1;
        justify-content: center;
        align-items: flex-start;
        img {
            animation: spin 4s linear infinite;
            width: 100px;
            margin-top: 100px;
        }
    }
    .hide-overlay.active {
        pointer-events: auto;
        opacity: 1;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity 250ms cubic-bezier(0.95, 0.05, 0.795, 0.035);
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
    @media only screen and (min-width: 1030px) {
        .vue-sticky-el {
            background-color: transparent;
            transition: background-color 250ms;
            &.top-sticky {
                background: $brand-paleblue;
                .filters {
                    padding-top: 20px;
                    margin-bottom: 20px;
                    select {
                        background-color: #fff;
                        padding: 11px 26px;
                    }
                    .select::after {
                        bottom: 8px;
                    }
                }
            }
            .filters {
                transition: padding-top 250ms, margin-bottom 250ms;
                padding-top: 0;
                select {
                    background-color: transparent;
                    transition: background-color 250ms, padding 250ms;
                }
                .select::after {
                    transition: bottom 250ms;
                }
            }
        } 
    }
    @include media-query-green {
        .vue-sticky-el {
            position: relative !important;
            top: 0 !important;
        }
        .vue-sticky-placeholder {
            padding-top: 0 !important;
        }
    }
}
