/// Footer CTA
.footer-cta {
    text-align: center;
    padding: 80px ($gutter * 2);
    border-bottom: 2px solid $brand-lightgray;
    p {
        line-height: 1.2;
        font-weight: 400;
    }
    a {
        font-family: $font-serif;
        font-size: 30px;
        color: $brand-lightblue;
        transition: color 125ms;
        i {
            vertical-align: -2px;
            transition: transform 125ms;
        }
        &:hover {
            color: lighten($brand-lightblue, 10%);
            i {
                transform: translateX(5px);
            }
        }
    }
}