// Text Input

.text,
.hs-fieldtype-text {
    label {
        display: block;
        font-family: $font-sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: $gutter * .5;
        span.required {
            margin-left: 5px;
            color: #fff;
        }
    }
    input {
        background: #fff;
        font-size: 18px;
        padding: 19px 26px;
        border: none;
        border-radius: 5px;
        width: 100%;
        outline: none;
        color: $brand-text;
        transition: border 125ms, background 125ms;
        font-family: $font-sans-serif;
        border: 2px solid $brand-lightgray;
        &:focus {
            border: 2px solid $brand-skyblue;
        }
    }

    // Defaults
    label {
        color: $brand-darkblue;
    }
    input::placeholder {
        color: $brand-gray;
    }

    // Light Text Input
    &.--light {
        label {
            color: $brand-yellow;
        }
        input::placeholder {
            color: $brand-gray;
        }
        input:focus {
            background: $brand-paleblue;
        }
    }

    // Dark Text Input
    &.--dark {
        input {
            border: 2px solid $brand-paleblue;
        }
        input:focus {
            
        }
    }
}