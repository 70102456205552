// Author/Byline

.author {
    color: $brand-darkblue;
    p.name {
        font-weight: 700;
        line-height: 1;
    }
    p.title {
        font-size: 16px;
        font-style: italic;
    }
}