// Utility Classes and Styles
// 1. Container
// 2. For Pattern-Library
// 3. Resets
// 4. Lazy Loading

// 1. Container
.container {
    margin: 0 auto;
    max-width: $container;
    padding: 0;
    &.--blog {
        max-width: 1010px;
    }
    @include media-query-yellow { 
        padding: 0 $gutter;
    }
}

.container--small {
    max-width: 850px;
    margin: 0 auto;
}

// 2. For Pattern-Library
.pl-fonts {
    .avenir p {
        font-family: $font-sans-serif;
    }
    .volkhov p {
        font-family: $font-serif;
    }
    span.one {
        font-weight: 100;
    }
    span.two {
        font-weight: 200;
    }
    span.three {
        font-weight: 300;
    }
    span.four {
        font-weight: 400;
    }
    span.five {
        font-weight: 500;
    }
    span.six {
        font-weight: 600;
    }
    span.seven {
        font-weight: 700;
    }
    span.eight {
        font-weight: 800;
    }
    span.nine {
        font-weight: 900;
    }
}

.ndpl-nav__items {
    li {
        margin-bottom: 0;
    }
}

// 3. Resets
body,
html {
    overflow-x: hidden;
}
fieldset {
    border: none;
}

* {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding-left: 0;
}
ol {
    li {
        margin-bottom: 15px;
    }
}
ol ol {
    list-style-type: lower-alpha;
    li:first-child {
        margin-top: 15px;
    }
}
ol ol ol {
    list-style-type: lower-roman;
}

// 4 Lazy Loading
.lazyload, .lazyloading { opacity: 0; }
.lazyloaded { opacity: 1; -webkit-transition: opacity 500ms; transition: opacity 500ms; }

// 5 Hubspot
.hs-error-msgs {
    padding-left: 0;
    li::before {
        display: none;
    }
    li label {
        color: $brand-orange;
        font-size: 12px;
    }
}

.submitted-message {
    a {
        color: $brand-paleblue;
        text-decoration: underline;
    }
}