.career-opportunities {
    h2 {
        margin-bottom: $gutter * 2 !important;
    }
    .open-positions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .position {
            width: calc(33.333% - 20px);
            margin-bottom: 100px;
            margin-right: 30px;
            position: relative;
            &:nth-child(3n) {
                margin-right: 0;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 150px;
                height: 120px;
                background: url('/img/patterns/bhrandtwo.png');
                top: -30px;
                left: -50px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: 0;
                opacity: .2;
            }
            .eyebrow,
            a {
                position: relative;
                z-index: 1;
            }
        }
        .-feature {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            line-height: 30px;
        }
    }
    &.--dark {
        background: $brand-darkblue;
        h2,
        .eyebrow.-feature,
        a {
            color: $brand-paleblue;
        }
        .eyebrow.-service {
            margin-top: 40px;
            color: $brand-yellow;
        }
        .position::after {
            opacity: .5;
        }
        a {
            font-weight: 900;
            font-size: 18px;
            &:hover {
                color: $brand-yellow;
            }
        }
    }
    @include media-query-green {
        .open-positions {
            padding-left: 50px;
            .position {
                width: calc(50% - 15px);
                &:nth-child(3n) {
                    margin-right: 30px;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
    @include media-query-purple {
        .open-positions {
            .position {
                width: 100%;
                margin-right: 0;
                &:nth-child(3n),
                &:nth-child(2n) {
                    margin-right: 0;
                }

            }
        }
    }
}