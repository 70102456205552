.service-module {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $brand-lightgray;
    padding-bottom: $gutter * 2.5;
    margin-bottom: $gutter * 2.5;
    h2 {
        font-size: 38px;
        margin-bottom: $gutter * 2;
    }
    .cta {
        width: 32%;
        padding-right: $gutter * 2;
        p.eyebrow {
            font-weight: 800;
        }
        .button {
            margin-bottom: $gutter * .5;
        }
    }
    .info {
        width: 68%;
        p {
            margin-bottom: 50px;
            line-height: 1.7;
        }
        p.eyebrow {
            margin-bottom: 0;
        }
    }
    .fact-columns {
        columns: 2;
        column-gap: $gutter;
    }
    .fact {
        break-inside: avoid;
    }
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
    &.--infographic {
        padding-bottom: 0;
        margin-bottom: 0;
        img {
            width: 100%;
        }
    }
    @include media-query-green {
        flex-direction: column;
        .cta,
        .info {
            width: 100%;
        }
        .cta {
            h2 {
                margin-bottom: $gutter * .5;
            }
            .button {
                margin-bottom: $gutter;
            }
        }
        &.--infographic .cta {
            margin-bottom: $gutter;
        }
    }
    @include media-query-blue {
        .fact-columns {
            columns: 1;
        }
    }
}

.subservices h2.--feature,
.subservices .subhead {
    margin-bottom: 50px;
}

.subservices {
    padding-top: 100px;
}