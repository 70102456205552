.entry-meta {
    background: $brand-paleblue;
    padding: 30px 35px;
    width: 380px;
    padding-bottom: $gutter;
    border-top: 5px solid $brand-green;
    box-shadow: 0 0 60px rgba(#000, .3);
    margin-left: 30px;

    &.--blog {
        text-align: center;
        .entry-meta-title {
            font-size: 1.15rem;
            margin-bottom: 23px;
            color: #000;
        }
        .blog-avatar {
            width: 135px;
            height: 135px;
            overflow: hidden;
            margin: 0 auto;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        .blog-avatar-photo {
            width: 100%;
        }
        .author-name,
        .author-title,
        .author-bio *  {
            color: $brand-darkblue;
            font-size: .95rem;
        }
        .author-name {
            font-weight: 700;
        }
        .author-title {
            font-style: italic;
        }
        .author-bio {
            margin-top: 15px;
        }
        .button {
            margin-top: 30px;
        }
    }
    h3 {
        color: #000;
        margin-bottom: 10px;
        font-family: $font-sans-serif;
        font-weight: 800;
        font-size: 18px;
    }
    ul {
        margin: 0;
        li {
            margin-bottom: 10px;
            font-size: 16px;
        }
        a {
            font-size: 16px;
        }
    }
    @include media-query-blue {
        .location,
        .about {
            width: 100%;
        }
        .services ul {
            li {
                width: 100%;
            }
        }
    }
}