.related-blogs {
    padding: 80px 0;
    background-color: $brand-lightgray;

    &-title {
        text-align: center;
        font-size: 2.2rem;
        margin-bottom: 30px;
    }
    .wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -30px;
        margin-right: -30px;
    }
    .item-card {
        width: calc(33.333% - 60px);
        margin: 0 30px;
        border-top: none;
        border-radius: 5px;
        box-shadow: 0px 0px 20px rgba(0,0,0, 0.4);
    }
    .item-card.--blog .item-card-text {
        padding-bottom: 25px;
    }
    .container {
        max-width: 1010px;
    }

    @include media-query-green {
        .item-card {
            width: calc(50% - 60px);
        }

    }

    @include media-query-purple {
        padding: 30px 0;
        .item-card {
            width: calc(100% - 60px);
        }


    }
}