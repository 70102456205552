/// Service Cards

.services-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .service-card {
        width: calc(33.3333% - 20px);
        margin-right: $gutter;
        margin-bottom: $gutter;
    }
    .service-card:nth-child(3n) {
        margin-right: 0;
    }
    @include media-query-green {
        .service-card {
            width: calc(50% - 15px);
        }
        .service-card:nth-child(2n) {
            margin-right: 0;
        }
        .service-card:nth-child(3n) {
            margin-right: $gutter;
        }
    }
    @include media-query-blue {
        .service-card {
            width: 100%;
        }
        .service-card:nth-child(2n),
        .service-card:nth-child(3n),
        .service-card {
            margin-right: 0;
        }
    }
}