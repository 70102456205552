.contact-modal {
    background-color: rgba(244, 248, 250, 0.95);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
        0 6px 20px 0 rgba(165, 200, 213, 0.41);
    z-index: 1000;
    display: none;
    .close {
        color: $brand-darkblue;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        background: none;
        border: none;
        font-size: 1.5rem;
    }
    .modal-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .contact-form {
        background: #fff;
        border-radius: 10px;
        max-width: 600px;
        padding: $gutter;
        position: relative;
        overflow-y: scroll;
    }
    .formContainer {
        height: 100%;
    }
    .hs-form-field {
        margin-bottom: 20px !important;
    }
    .hs-richtext {
        p {
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
    .contact-form form {
        img {
            width: auto !important;
            height: 40px;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
        .hs-fieldtype-textarea {
            margin-bottom: 10px;
        }
        input,
        textarea {
            padding: 11px 16px;
        }
        label {
            margin-bottom: 0;
            font-size: 0.9rem;
        }
    }
    @include media-query-purple {
        .contact-form {
            height: 100vh;
            width: 100vw;
            border-radius: 0;
        }
    }
    @include media-query-green {
        .hsForm {
            margin-top: 0;
            height: 100%;
            a.close {
                margin: 16px 0 0 -16px;
            }
        }
    }
}

.hbspot-email {
    width: 35%;
    margin: 0 auto 2rem auto;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid $brand-lightgray;

    .hs-fieldtype-text {

        input {
            padding: 10px 26px;
            margin-right: 2rem;
        }
    }

    .hs_email {
        display: inline-block;
    }

    .input {
        display: inline-block;
        margin-right: .5rem;
    }

    input[type=submit] {
        background: #0FAF91 !important;

        &:hover {
            background: #13deb8 !important;
        }
    }

    .hs_submit {
        display: inline-block;
    }

    .hs-button {
        padding: 17px 38px 12px;
    }
} 


// background: #0FAF91;
// background: #13deb8;