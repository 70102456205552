// Utility Navigation

nav.utility > ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-left: 0;
    & > li {
        text-align: right;
        margin-left: 40px;
        margin-bottom: 0;
        &::before {
            display: none;
        }
    }
    li .fas.fa-search {
        vertical-align: -3px;
    }

    @include media-query-green {
        display: none;
    }
}