/// Slider Setup
.slider {
    position: relative;
    .rail {
        display: flex;
        flex-direction: row;
    }
    .tray {
        flex-grow: 1;
    }
    .-slider-left,
    .-slider-right {
        position: absolute;
        top: 50%;
        color: $brand-paleblue;
    }
    .-slider-left {
        left: 0;
    }
    .-slider-right {
        right: 0;
    }
}