/// Trend Card

.item-card {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    transition: transform 250ms, box-shadow 500ms;
    transform: translateY(0px);
    box-shadow: 0px 0px $gutter rgba($brand-darkblue, .2);
    min-height: 400px;

    p {
        color: #000;
        transition: color 250ms;
    }

    &-time {
        color: #333333 !important;
        font-size: 1rem;
        margin-bottom: 20px;
    }
    &-time::before {
        @include fa-icon;
        @extend .fal;
        content: fa-content($fa-var-clock);
        margin-right: 6px;
        vertical-align: -1px;
    }

    //Has Profile Page Disabled
    &-noHover{
    
        &:hover {
            transform: translateY(0px) !important;
        }
    }

    // Trend Modifier
    &.--trend {
        .image {
            height: 175px;
            overflow: hidden;
            position: relative;
            border-bottom: 1px solid $brand-lightgray;
            img {
                position: absolute;
                top: -9999px;
                right: -9999px;
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                min-width: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
        @include media-query-purple { 
            .image img {
                min-height: 0;
                max-width: 100%;
            }
        }
    }

    // Resource Modifier
    &.--resource {
        min-height: 0;
        .image {
            height: 175px;
            overflow: hidden;
            position: relative;
            border-bottom: 1px solid $brand-lightgray;
            img {
                position: absolute;
                top: -9999px;
                right: -9999px;
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                min-width: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
        .link {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    // Blog Modifier
    &.--blog {
        padding-bottom: 0;
        .logo {
            img {
                width: 100%;
            }
        }
        p.item-card-header,
        .author {
            margin-bottom: $gutter * .5;
        }
        .link {
            text-transform: uppercase;
            font-weight: 700;
        }
        .item-card-text {
            padding-bottom: $gutter * 1.5;
        }
    }

    // Blog Item Modifier
    &.--blog-item {
        border-top: 6px solid $brand-green;
        padding-bottom: 0;
        min-height: auto;
        .logo {
            img {
                width: 100%;
            }
        }
        .item-card-header {
            text-align: center;
        }
        .button {
            padding: 13px 25px;
            font-size: .85rem;
            border: 1px solid transparent;
            margin-bottom: 30px;
        }
        p.item-card-header,
        .author {
            margin-bottom: $gutter * .5;
        }
        .link {
            text-transform: uppercase;
            font-weight: 700;
        }
        .item-card-text {
            padding-bottom: $gutter * 1.5;
            text-align: center;
        }
        .blog-topic {
            display: inline-block;
            color: $brand-orange;
            font-size: .9rem;
        }
        .topic-divider {
            margin: 0 10px;
        }
        &:hover .button {
            padding: 13px 25px;
            border: 1px solid #fff;
        }
        &:hover .item-card-time,
        &:hover .blog-topic {
            color: #fff !important;
        }
    }

    //Event Modifier 
    &.--event {
        min-height: 0;
        .item-card-header {
            margin-bottom: 6px;
        }
        .image {
            height: 175px;
            overflow: hidden;
            position: relative;
            border-bottom: 1px solid $brand-lightgray;
            img {
                position: absolute;
                top: -9999px;
                right: -9999px;
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                min-width: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
        .intro {
            margin-bottom: 10px;
        }
        .item-card-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            & > * {
                width: 100%;
            }
        }
        hr {
            opacity: .3;
            margin-bottom: 12px;
            width: 100%;
        }
        .meta {
            color: $brand-gray;
            letter-spacing: 1px;
        }
        .button {
            width: auto;
        }
        .buttons {
            margin-top: auto;
        }
        .date span:not(:first-child)::before {
            display: none;
        }
        .location {
            margin-bottom: 40px;
            .meta::before {
                display: none;
            }
            i {
                font-weight: 800;
                margin-right: 8px;
                vertical-align: 0px;
                color: $brand-yellow;
                font-size: 14px;
            }
        }
        &:hover {
            transform: translateY(0px);
            box-shadow: 0px 0 ($gutter * 1.5) rgba($brand-darkblue, .5);

            .item-card-text {
                background: #fff;
            }
            .eyebrow {
                color: $brand-orange;
            }
            .item-card-header {
                color: $brand-darkblue;
            }
            p {
                color: $brand-text;
            }
            .button {
                color: #fff;
            }
            .button.-outline {
                color: $brand-darkblue;
            }
        }
        &.--emphasize {
            hr {
                display: none;
            }
            .item-card-header {
                font-size: 30px;
                margin-bottom: 30px;
            }
            .intro {
                margin-bottom: 18px;
            }
            .item-card-text {
                background: url('/img/patterns/circlethreeopacity.png'), #fff;
                background-position: top -40px right -36px;
                background-size: 370px;
                background-repeat: no-repeat;
            }
        }
    }

    // Service Modifier
    &.--service {
        min-height: auto;
        .image {
            height: 175px;
            overflow: hidden;
            position: relative;
            border-bottom: 1px solid $brand-lightgray;
            & > img {
                position: absolute;
                top: -9999px;
                right: -9999px;
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                min-width: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
        .icon {
            background: rgba($brand-purple, .8);
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 173px;
            border-top-right-radius: 90px 110px;
            border-bottom-right-radius: 90px 110px;
            text-align: left;
            padding-left: 20px;
            img {
                height: 100px;
                margin-top: 36px;
            }
        }
        
    }

    // Team Modifier 
    &.--team {
        border-radius: 0;
        box-shadow: none;
        min-height: 0;
        .avatar {
            height: 250px;
            overflow: hidden;
            position: relative;
            margin-bottom: 15px;
            img {
                position: absolute;
                top: -9999px;
                right: 0;
                bottom: -9999px;
                margin: auto;
                min-width: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
        .name {
            text-align: center;
            font-family: $font-serif;
            font-size: 24px;
            font-weight: 800;
            color: $brand-darkblue;
            margin-bottom: 9px;
        }
        .title {
            text-align: center;
            font-family: $font-serif;
            font-size: 18px;
            color: #000;
            padding-top: 12px;
            position: relative;
            &::before {
                content: '';
                width: 30px;
                display: block;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                border-top: 2px solid $brand-yellow;
                position: absolute;
            }
        }
        &:hover {
            box-shadow: none;
        }
    }

    // CTA Modifier
    &.--ctacard {
        min-height: 0;
        .image {
            height: 180px;
            position: relative;
            overflow: hidden;
            img {
                min-width: 100%;
                min-height: 100%;
                position: absolute;
                top: -9999px;
                right: -9999px;
                bottom: -9999px;
                left: -9999px;
                margin: auto;
                width: 100%;
            }
        }
        .item-card-text {
            display: flex;
            flex-direction: column;
        }
        .item-card-header {
            font-size: 28px;
        }
        .button {
            margin-top: auto;
            align-self: flex-start;
        }
        &:hover {
            transform: translateY(0);
            .item-card-text {
                background: #fff;
            }
            .item-card-header {
                color: $brand-darkblue;
            }
            p {
                color: $brand-text;
            }
            .button {
                color: #fff;
            }
        }
    }

    // Adds defining border in case of dark background
    &.--light {
        border-radius: 10px;
    }

    .item-card-text {
        padding: 25px;
        background: #fff;
        transition: background 250ms;
        text-align: left;
        flex-grow: 1;
    }



    p.eyebrow {
        margin-bottom: 10px;
        transition: color 250ms;
    }

    .item-card-pricing {
        font-size: 1.5em;
        font-weight: 700;
        text-align: right;
    }

    p.item-card-header {
        font-family: $font-serif;
        color: $brand-darkblue;
        transition: color 250ms;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    i.fas.fa-chevron-right,
    .link {
        position: absolute;
        display: block;
        bottom: 25px;
        right: 25px;
        color: $brand-darkblue;
        font-size: 13px;
    }
    &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 0 ($gutter * 1.5) rgba($brand-darkblue, .5);

        .item-card-text {
            background: $brand-green;
        }
        .eyebrow {
            color: lighten($brand-green, 50%);
        }
        .item-card-header {
            color: #fff;
        }
        p {
            color: #fff;
        }
    }
}