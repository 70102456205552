/// Service Card

.service-card {
    border: 2px solid $brand-lightgray;
    padding: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 150px;
    .icon {
        height: 90px; 
        margin-bottom: $gutter;
        img {
            height: 100%;
        }
    }
    h3 {
        margin-bottom: 14px;
        color: $brand-darkblue;
        line-height: 1.2;
    }

    .subdescription {
        text-align: left;
    }

    .button-wrapper {
        position: absolute;
        width: 100%;
        bottom: 40px;
        left: 0;
    }
}