.stats {
    background: $brand-paleblue;
    padding: $gutter 0;
    .stats-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .stat {
        flex-grow: 1;
        text-align: center;
    }
    .value {
        font-family: $font-serif;
        color: $brand-darkblue;
        font-size: 48px;
        font-weight: 700;
    }
    .qualifier {
        margin-top: $gutter;
        font-style: italic;
        font-size: 12px;
    }
    .stat-text {
        font-size: 24px;
        color: $brand-darkblue;
    }
    @include media-query-yellow {
        .stat {
            width: 50%;
            margin-bottom: $gutter;
        }
    }
    @include media-query-green {
        .stat {
            width: 100%;
        }
    }
}