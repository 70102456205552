.bio-detail {
    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .entry-meta {
        min-width: 410px;
        margin-left: 100px;
    }
    .bio {
        p {
            margin-bottom: $gutter;
        }
    }
    .bio-detail-simplephoto {
        float: right;
        min-width: 200px;
        max-width: 300px;
        max-height: 500px;
        margin-left: $gutter;
        margin-bottom: $gutter;

    }
    @include media-query-yellow {
        .wrapper {
            flex-direction: column;
        }
        .entry-meta {
            margin-left: 0;
            min-width: 0;
            width: 100%;
            max-width: 410px;
        }
    }
    @include media-query-purple {
        .bio-detail-simplephoto {
            float: none;
            margin-left: 0;
            margin-bottom: $gutter;
        }
    }
}