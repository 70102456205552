/// Resource Ctas

.resource-ctas {
    display: flex;
    flex-direction: row;
    .resource-cta {
        margin-right: $gutter;
        width: calc(50% - 15px);
        &:last-child {
            margin-right: 0;
        }
    }
    @include media-query-green {
        flex-direction: column;
        .resource-cta {
            width: 100%;
            margin-bottom: $gutter;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}