.pagination {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 10px 0;
    .button {
        text-transform: none;
        padding: 0;
        flex-grow: 1;
        font-size: 18px;
        max-width: 100px;
    }
    .active {
        text-decoration: underline;
        color: $brand-lightblue;
    }
    @include media-query-blue{
        .button {
            display: none;
        }
        .previous,
        .next {
            display: block;
        }
    }
}