.programs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    .category-nav {
        width: 308px;
        min-width: 308px;
    }
    .categories {
        flex-grow: 1;
        .category:first-child {
            padding-top: 0;
            &::before,
            &::after {
                display: none;
            }
        }
    }
    &.sticky {
        .category-nav {
            position: fixed;
            top: 166px;
        }
        .categories {
            margin-left: 308px;
        }
    }
    &.bottom-sticky {
        .category-nav {
            position: absolute;
            left: 0 !important;
            bottom: 70px;
        }
        .categories {
            margin-left: 308px;
        }
    }
    @include media-query-green {
        .category-nav {
            display: none;
        }
        .categories,
        &.sticky .categories {
            margin-left: 0;
            .category {
                padding-left: 0;
            }
        }
        &.bottom-sticky {
            .categories {
                margin-left: 0;
            }
        }
    }
}