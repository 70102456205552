/// Headers

//Global
h1, h2, h3 {
    font-weight: 700;
    font-family: $font-serif;
    margin: 0;

    // Default
    color: $brand-darkblue;

    // Darker
    &.--dark {
        color: $brand-darkblue;
    }

    // Lighter
    &.--light {
        color: $brand-lightblue;
    }

    // Pale 
    &.--pale {
        color: $brand-paleblue;
    }
}

// H1
h1 {
    font-size: 65px;
    line-height: 1.3;
    // Media Queries

    &.--hero {
        font-size: 45px;
        color: #fff;
        margin-bottom: 11px;
    }
    @include media-query-blue {
        font-size: 30px;
        &.--hero {
            font-size: 30px;
        }
    }
}

// H2
h2 {
    font-size: 48px;

    &.--feature {
        font-size: 72px;
        font-family: $font-sans-serif;
        font-weight: 100;
        color: $brand-text;
        line-height: 1.3;
        max-width: 860px;
        margin-bottom: 100px;
        &::after {
            content: '.';
            color: $brand-yellow;
            font-size: 180px;
            line-height: 0;
            vertical-align: -12px;
        }
    }

    @include media-query-blue {
        font-size: 38px;
        &.--feature {
            font-size: 36px;
            margin-bottom: 50px;
            &::after {
                vertical-align: -10px;
                font-size: 111px;
            }
        }
    }
}

h3 {
    // Default
    color: $brand-lightblue;
    font-size: 28px;

    // Section Header
    &.--section {
        font-size: 35px;
    }

    //Molecule Header
    &.--molecule {
        font-size: 28px;
    }
}