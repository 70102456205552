/// Client Logos

.client-logos {
    img {
        max-height: 80px;
    }
    a {
        width: 100%;
        text-align: center;
        display: block;
    }
    @include media-query-green {
        display: block;
        padding: 0 $gutter;
        text-align: center;
        img {
            margin-right: $gutter * .5;
            margin-bottom: $gutter * .5;
        }
    }
}