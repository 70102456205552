.category-nav {
    .eyebrow.-large {
        font-weight: 900;
    }
    ul {
        padding-left: $gutter;
        li {
            color: $brand-gray;
            font-weight: 700;
            margin-bottom: 25px;
            transition: color 250ms;
            cursor: pointer;
            &.active {
                color: $brand-orange;
                font-size: 22px;
                font-weight: 900;
                position: relative;
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -30px;
                    background: url('/img/icons/navarrow.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                    opacity: 1;
                }
            }
            &:hover {
                color: $brand-orange;
            }
            &::before {
                opacity: 0;
                transition: opacity 250ms;
                border-top: none;
            }
        }
    }
}