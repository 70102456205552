table {
    border-collapse: collapse;
    thead tr:last-child td {
        border-bottom: 2px solid $brand-darkblue;
        padding-bottom: $gutter * .5;
    }
    td, th {
        padding: 20px;
        min-width: 190px;
    }
    th {
        text-align: left;
        vertical-align: bottom;
    }
    thead td,
    thead th {
        padding: 5px;
    }
    td.center {
        text-align: center;
    }
    tbody > tr:nth-child(even) {
        background: $brand-paleblue;
    }
    tbody > tr > td {
        padding-top: 40px;
        padding-bottom: 40px;
        border-right: 1px solid rgba($brand-darkblue, .25);
        &:last-child {
            border-right: none;
        }
    }
}

.sticky-head {
    transition: background 250ms, padding-top 250ms;
    padding-top: 0;
    background: #fff;
    &.floated {
        padding-top: 15px;
        background: $brand-paleblue;
    }
}