.membership, .become-a-member {
    .contact-container {
        padding-top: $gutter * 3;
        padding-bottom: $gutter * 4;
        .contact-form {
            position: relative;
            z-index: 2;
        }
        &.lazyloaded {
            background: url('/img/patterns/circlethree.png'), $brand-darkblue;
            background-position: right -623px top 91%;
            background-size: 1130px;
            background-repeat: no-repeat;
        }
        .hs-form {
            label {
                color: $brand-yellow;
            }
            .hs-button {
                background: $brand-green;
                color: #fff;
                &:hover {
                    background: lighten($brand-green, 10%);
                }
            }
            .hs-submit {
                margin: 0;
            }
            .hs-error-msgs label {
                color: $brand-paleblue;
            }
        }
        .submitted-message p {
            color: $brand-paleblue;
        }
    }

    @include media-query-purple { 
        .contact-container {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            .contact-form {
                margin-top: 0;
            }
        }
    }

    @include media-query-green {
        .contact-container.lazyloaded {
            background-image: none;
        }

        
    }

    @include media-query-yellow {
        .contact {
            background-position: right -306px top -16%;
            background-size: 540px;
        }
    }
}