/// Basic CTA
.cd-cta {
    padding: 100px 0;
    text-align: center;

    .headline {
        color: $brand-darkblue;
        // font-size: 38px;
        margin-bottom: 40px;
    }
    p {
        margin-bottom: 40px;
    }

    // Default Configuration
    &.style-default {
        background: $brand-lightgray;

        .wrapper {
            max-width: 790px;
            margin: 0 auto;
        }
    }

    // Wide Configuration
    &.style-wide {
        background: $brand-paleblue;
        text-align: left;
        .wrapper {
            display: flex;
            flex-direction: row;
            .headline {
                width: 30%;
                padding-right: $gutter;
            }
            .content {
                width: 70%;
            }
        }
    }
    @include media-query-green {
        &.style-wide {
            .wrapper {
                flex-direction: column;
                .headline,
                .content {
                    width: 100%;
                }
            }
        }
    }
}

// Content-Designer Reset
.content-designer .cd-cta h3 {
    margin-top: 0;
}

.content-designer .cd-cta p {
    margin-bottom: 40px;
}

.content-designer .cd-cta p.button-container {
    margin-bottom: 0;
}