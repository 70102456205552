/// Links
///

a,
.link,
.nav-link {
    text-decoration: none;
    font-size: 18px;
    font-family: $font-sans-serif;
    font-weight: 400;
    transition: color 250ms;
    
    // Defaults
    color: $brand-darkblue;
    &:hover {
        color: $brand-lightblue;
    }

    // Dark link
    &.--dark {
        color: $brand-darkblue;
        &:hover {
            color: $brand-lightblue;
        }
    }
    
    // Light Link
    &.--light {
        color: $brand-lightblue;
        &:hover {
            color: $brand-darkblue;
        }
    }

    //CTA 
    &.-cta {
        font-size: 16px;
        &::after {
            @include fa-icon;
            @extend .far;
            content: fa-content($fa-var-arrow-right);
            margin-left: 10px;
            vertical-align: -.025em;
            font-size: .8rem;
            transition: transform 125ms;
        }
        &:hover {
            &::after {
                transform: translateX(5px);
            }
        }
    }
}

.content-designer p a {
    color: #2db9e1;

    &:hover {
        text-decoration: underline;
    }
}