/// Resource Cta

.resource-cta {
    background: url('/img/patterns/ctatriangle.png') $brand-darkblue;
    background-position: -36px -9px;
    background-repeat: no-repeat;
    background-size: 120px;
    transition: background-size 250ms, background-position 250ms;
    position: relative;
    overflow: hidden;
    padding: 23px 50px 19px 169px;
    border-radius: 10px;
    display: block;
    box-shadow: -2px 4px 18px rgba(#000, .6);
    
    .--cta p,
    p.--cta {
        color: $brand-paleblue;
        display: inline;
        margin-right: $gutter * .25;
    }
    span {
        display: inline;
        font-family: $font-sans-serif;
        font-weight: 400;
        color: $brand-lightblue;
        font-size: 18px;
    }

    .cta-image {
        position: absolute;
        top: $gutter * .5;
        left: $gutter * .5;
        max-width: 125px;
        img {
            width: 100%;
        }
    }
    p {
        margin: 0;
    }
    &:hover {
        background-size: 130px;
        background-position: -46px -19px;
        i.fas.fa-chevron-right {
            transform: translateX(5px);
        }
    }
    i.fas.fa-chevron-right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        color: $brand-paleblue;
        height: 100%;
        transition: transform 250ms;
        &::before {
            position: absolute;
            top: calc(50% - 8px);
            right: $gutter;
            margin: auto;
        }
    }
    @include media-query-purple {
        padding: 12px 50px 12px 12px;
        background-image: none;
        .cta-image {
            display: none;
        }
    }
}