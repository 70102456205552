.perspective {
    padding-bottom: 120px;
    .container {
        display: flex;
        flex-direction: row;
        .image {
            margin-right: -60px;
            position: relative;
            img {
                width: 571px;
                display: block;
            }
            img.mobile {
                display: none;
            }
        }
        .text {
            padding-top: $gutter * 3;
        }
    }
    @include media-query-green {
        .container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        
            .image {
                margin-right: 0;
                img {
                    display: none;
                }
                img.mobile {
                    display: block;
                    width: 100%;
                }
            }
            .text {
                padding-top: 0;
            }
        }
    }
}