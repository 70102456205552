.team-index {
    main {
        padding-top: 120px;
    }
    .cd-cta {
        h2 {
            font-size: 38px;
        }
        .button-container {
            margin-bottom: 0;
        }
    }
    .jump-nav ul {
        justify-content: center;
        li {
            margin-right: 14%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @include media-query-blue {
        .jump-nav ul {
            li {
                margin-right: 0;
            }
        }
    }
    @include media-query-purple {
        main {
            padding-top: 40px;
        }
    }
}