/// Default Hero

.hero {
    padding-top: 250px;
    padding-bottom: 60px;
    background-position: top center;
    background-size: cover;
    position: relative;
    z-index: 0;

    h1.--hero {
        max-width: 600px;
    }

    .subhead {
        max-width: 580px;
        z-index: 2;
        position: relative;
    }

    &.--blog {
        padding-top: 221px;
        padding-bottom: 108px;
        background: url('/img/patterns/blogspiral.png') $brand-darkblue;
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: 150% 80%;
        margin-bottom: 60px;

        &::after {
            display: none;
        }
    }

    &.--blog-post {
        padding-top: 181px;
        padding-bottom: 68px;
        margin-bottom: 90px;

        h1 {
            margin-bottom: 30px;
        }
        .blog-author {
            font-weight: 700;
            font-size: 1.2rem;
            color: $brand-lightblue;
        }

        .read-time {
            color: #fff;
            font-weight: 300;
            font-size: 1rem;
        }
        
        .read-time::before {
            @include fa-icon;
            @extend .fal;
            content: fa-content($fa-var-clock);
            margin-right: 6px;
            vertical-align: -1px;
        }
        @include media-query-blue {
            padding-top: 100px;
            padding-bottom: 50px;
            background-position: 100% 30%;
        }
    }

    &.--success {
        padding-top: 385px;
        padding-bottom: 0;
        clip-path: none;
        margin-bottom: 0;
        h1.--hero {
            padding-bottom: 90px;
        }
        h1.--hero span.district {
            display: block;
            font-family: $font-sans-serif;
            font-size: 24px;
            font-weight: 400;
            position: relative;
            &::before {
                @include fa-icon;
                @extend .fas;
                content: fa-content($fa-var-school);
                color: $brand-yellow;
                font-weight: 600;
                vertical-align: 2px;
                font-size: 18px;
                margin-right: 13px;
                margin-top: 10px;
            }
        }
        .entry-meta {
            z-index: 2;
            margin-bottom: -100px;
        }
        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        } 

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba($brand-darkerblue, .8), transparent);
            z-index: 1;
        }
    }

    &.--team {
        padding-top: 300px;
        clip-path: none;
        height: 729px;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        margin-bottom: 75px;
        .--hero {
            margin-bottom: 17px;
        }
        .subhead {
            font-size: 30px;
            font-weight: 300;
            margin-bottom: 40px;
        }
        .avatar-mobile {
            display: none;
        }
        .testimonial {
            max-width: 580px;
            margin-right: auto;
            margin-left: 0;
            blockquote::before,
            blockquote p::after {
                color: $brand-yellow;
                z-index: 2;
            }
            blockquote p {
                color: #fff;
                font-size: 22px;
                font-family: $font-serif;
                font-weight: 400i;
                font-style: italic;
                line-height: 38px;
            }
        }
        &.--simple {
            height: auto;
        }
    }

    &.--service {
        clip-path: none;
        margin-bottom: 0;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba($brand-darkerblue, .8) 30%, transparent 58%);
        z-index: 1;
    }

    h1, p {
        position: relative;
        z-index: 2;
    }

    @include media-query-green { 
        padding-top: 120px;
        &::after {
            background: rgba($brand-darkerblue, .8);
        }
        &.--success {
            padding-top: 90px;
            .wrapper {
                flex-direction: column;
            }
            .entry-meta {
                width: 100%;
                margin-left: 0;
                margin-bottom: 0;
            }
            .container {
                padding: 0;
            }
            h1.--hero {
                padding: 0 ($gutter * .5);
                width: 100%;
                max-width: 100%;
                padding-bottom: $gutter;
            }
            &::after {
                background: rgba($brand-darkerblue, .8);
            }
        }

        &.--blog {
            padding-top: 100px;
            padding-bottom: 38px;
        }
        &.--team {
            padding-top: 0;
            padding-bottom: 0;
            height: auto;
            &::after {
                background: none;
            }
            .avatar-mobile {
                display: block;
                position: relative;
                height: 420px;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    min-height: 100%;
                    max-height: 100%;
                    min-width: 100%;
                }
            }
            .container {
                background: #fff;
                padding-top: $gutter;
                blockquote p,
                .subhead {
                    color: #000;
                }
                .testimonial {
                    max-width: 100%;
                }
                .subhead {
                    font-size: 20px;
                }
                h1 {
                    color: $brand-darkblue;
                }
            }
        }
    }
    @include media-query-green {

        &.--team.--simple {
            .avatar-mobile {
                height: 50px;
            }
        }
    }
    @include media-query-blue { 
        &.--team {
            .avatar-mobile {
                img {
                    right: -20%;
                }
            }
        }
    }  
    @include media-query-purple { 
        &.--team {
            .avatar-mobile {
                img {
                    right: -49%;
                }
            }
            &.--simple {
                margin-bottom: 35px;
            }
        }
    }

    @media (max-height: 700px){
        &.--success {
            padding-top: 190px;
        }
    }
}

.hero-footer {
    margin-bottom: $gutter * 2;
    padding-top: $gutter;

    .hero-graphic {
        width: 236px;
        height: 236px;
        border-radius: 50%;
        background: $brand-purple;
        margin-top: -100px;
        margin-left: 150px;
        position: relative;
        z-index: 1;
        float: right;
        text-align: center;
        img {
            max-width: 120px;
            margin-top: 50%;
            transform: translateY(-50%);
        }
    }

    &.no-image {
        min-height: 0;
        margin-bottom: 50px;
    }

    @include media-query-green {
        .hero-graphic {
            margin-left: $gutter;
            margin-bottom: $gutter;
        } 
    }

    @include media-query-blue { 
        .hero-graphic{
            width: 115px;
            height: 115px;
            img {
                max-width: 60px;
            }
        } 
    }

    @include media-query-purple { 
        .hero-graphic {
            width: 80px;
            height: 80px;
            margin-top: 0;
            img {
                max-width: 40px;
            }
        } 
    }

}