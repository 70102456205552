.blog-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
    .item-card {
        margin: 0 30px;
        width: calc(33.33% - 60px);
        margin-bottom: 60px;
    }

    @include media-query-green {
        .item-card {
            width: calc(50% - 60px);
        }

    }

    @include media-query-purple {
        .item-card {
            width: calc(100% - 60px);
        }


    }
}