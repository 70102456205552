body.error {
    main {
        padding-top: $gutter * 2;
    }
    .--feature {
        margin-bottom: $gutter;
    }
    .subhead {
        margin-bottom: $gutter;
    }
}