/// Two Columns

.columns.two-columns {
    .column-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > div {
            width: calc(50% - 30px);
        }
    }
    @include media-query-blue {
        .column-row {
            flex-direction: column;
            > div {
                width: 100%;
            }
        }
    }
}