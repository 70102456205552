/// Filters
.filters {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: $gutter * 2;
    h2 {
        font-size: 1.9rem;
        margin-right: $gutter;
    }
    .select {
        flex-grow: 1;
        margin-right: $gutter;
        &:last-child {
            margin-right: 0;
        }
    }
    @include media-query-blue {
        h2 {
            width: 100%;
            margin-bottom: $gutter;
        }
        .select {
            width: 100%;
            margin-bottom: $gutter;
            margin-right: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}