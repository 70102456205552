.listed-features {
    padding: 80px 0;
    .wrapper {
        max-width: 895px;
        display: flex;
        flex-direction: column;
    }
    p, li, h2 {
        color: #fff;
    }
    h2 {
        margin-bottom: 60px;
    }
    p.--intro {
        margin-bottom: 40px;
    }
    ul {
        columns: 2;
        column-gap: 60px;
        display: inline-block;
        padding-left: 0;
    }
    li {
        break-inside: avoid;
        display: inline-block;
        padding-left: 40px;
        width: 100%;
        &::before {
            left: 0;
        }
        h4 {
            color: #fff;
            font-family: $font-serif;
            font-weight: 800;
            text-transform: none;
            font-size: 21px;
            margin-top: 0;
        }
    }
    &.checkmarks {
        ul {
            padding-left: 0;
            column-gap: 60px;
        }
        li {
            padding-left: 40px;
            display: inline-block;
        }
        li::before {
            border-top: none;
            @include fa-icon;
            @extend .fas;
            content: fa-content($fa-var-check-circle);
            color: $brand-yellow;
            font-weight: 400;
            font-size: 29px;
            top: 0;
            left: 0;
        }
    }
    &.lazyloaded {
        background: url('/img/patterns/circlethree.png'), $brand-darkblue;
        background-position: right -724px top 74%;
        background-size: 1070px;
        background-repeat: no-repeat;
    }
    @include media-query-blue {
        ul {
            columns: 1;
        }
    }
    @include media-query-green {
        background-image: none;
        &.lazyloaded {
            background: $brand-darkblue;
        }
    }
    @include media-query-yellow {
        background-position: right -306px top -16%;
        background-size: 540px;
    }
}