/// Image with Text
.image-with-text {
    margin-top: $gutter * 2;
    padding-bottom: $gutter * 2;
    .columns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .content {
            flex-grow: 1;
        }
        .image {
            max-width: 50%;
            min-width: 500px;
            img {
                box-shadow: 0 0 20px rgba($brand-darkblue, .4);
                max-width: 100%;
            }
            iframe {
                width: 100%;
            }
        }
    }
    &.pos-left {
        .image {
            margin-right: $gutter * 2;
        }
    }
    &.pos-right {
        .image {
            margin-left: $gutter * 2;
        }
    }
    &.--success-story {
        h2 {
            font-size: 30px;
        }
        .button {
            margin-top: 15px;
        }
        .eyebrow::before {
            @include fa-icon;
            @extend .fas;
            content: fa-content($fa-var-school);
            color: $brand-yellow;
            font-weight: 600;
            vertical-align: 2px;
            font-size: 18px;
            margin-right: 13px;
        }
    }
    @include media-query-green {
        .columns {
            flex-direction: column;
            .image {
                max-width: 100%;
                min-width: auto;
            }
        }
        &.pos-left .image {
            margin-bottom: $gutter;
            margin-right: 0;
        }
        &.pos-right .image {
            margin-top: $gutter;
            margin-left: 0;
        }
    }

}