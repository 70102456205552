/// Section Divider

.section-divider {
    width: 100%;
    overflow: hidden;
    height: 230px;
    display: block;
    position: relative;
    transform: translateY(1px);
    .icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 290px;
        height: 290px;
        border-radius: 50%;
        z-index: 2;
        .icon-wrap {
            display: block;
            position: absolute;
            top: -90px;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 112px;
            height: 112px;
            border-radius: 50%;
            background: $brand-lightblue;
            text-align: center;
            img {
                width: 85px;
            }
        }
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 93px;
        left: -10%;
        right: 0;
        margin: auto;
        width: 120%;
        height: 610px;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        z-index: 1;
    }

    // Defaults 
    .icon {
        background: #fff;
        .icon-wrap img {
            margin-top: 33px;
        }
    }
    &::after {
        background: #fff;
    }

    // Light Background
    &.--light {
        .icon {
            background: #fff;
            .icon-wrap img {
                margin-top: 33px;
                width: 72px;
            }
        }
        &::after {
            background: #fff;
        }
    }

    // Dark Background
    &.--dark {
        .icon {
            background: $brand-darkblue;
            .icon-wrap img {
                margin-top: 9px;
            }
        }
        &::after {
            background: $brand-darkblue;
        }
    }

    @include media-query-purple { 
        .icon {
            .icon-wrap {
                top: 60px;
            }
        }

        &::after {
            width: 180%;
            left: -40%;
        }

        &.--light,
        &.--dark {
            .icon {
                background: transparent;
            }
        }
    }
}