/**
 * @license
 * MyFonts Webfont Build ID 3715065, 2019-02-04T14:24:01-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-35-light/
 * 
 * Webfont: AvenirLTPro-LightOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-35-light-oblique/
 * 
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-45-book/
 * 
 * Webfont: AvenirLTPro-BookOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-45-book-oblique/
 * 
 * Webfont: AvenirLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-55-roman/
 * 
 * Webfont: AvenirLTPro-Oblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-55-oblique/
 * 
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium/
 * 
 * Webfont: AvenirLTPro-MediumOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium-oblique/
 * 
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy/
 * 
 * Webfont: AvenirLTPro-HeavyOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy-oblique/
 * 
 * Webfont: AvenirLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black/
 * 
 * Webfont: AvenirLTPro-BlackOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-95-black-oblique/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3715065
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/38aff9");


@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_0_0.eot');
    src: url('webfonts/38AFF9_0_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_0_0.woff2') format('woff2'), url('webfonts/38AFF9_0_0.woff') format('woff'), url('webfonts/38AFF9_0_0.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_2_0.eot');
    src: url('webfonts/38AFF9_2_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_2_0.woff2') format('woff2'), url('webfonts/38AFF9_2_0.woff') format('woff'), url('webfonts/38AFF9_2_0.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_4_0.eot');
    src: url('webfonts/38AFF9_4_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_4_0.woff2') format('woff2'), url('webfonts/38AFF9_4_0.woff') format('woff'), url('webfonts/38AFF9_4_0.ttf') format('truetype');
    font-weight: 300;
}


@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_6_0.eot');
    src: url('webfonts/38AFF9_6_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_6_0.woff2') format('woff2'), url('webfonts/38AFF9_6_0.woff') format('woff'), url('webfonts/38AFF9_6_0.ttf') format('truetype');
    font-weight: 600;
}


@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_8_0.eot');
    src: url('webfonts/38AFF9_8_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_8_0.woff2') format('woff2'), url('webfonts/38AFF9_8_0.woff') format('woff'), url('webfonts/38AFF9_8_0.ttf') format('truetype');
    font-weight: 700;
}


@font-face {
    font-family: 'Avenir';
    src: url('webfonts/38AFF9_A_0.eot');
    src: url('webfonts/38AFF9_A_0.eot?#iefix') format('embedded-opentype'), url('webfonts/38AFF9_A_0.woff2') format('woff2'), url('webfonts/38AFF9_A_0.woff') format('woff'), url('webfonts/38AFF9_A_0.ttf') format('truetype');
    font-weight: 800;
}