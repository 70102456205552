/// Subhead

.subhead,
.subhead p {
    font-size: 24px;
    font-family: $font-sans-serif;

    // Defaults
    color: $brand-darkblue;

    // Darker
    &.--dark {
        color: $brand-darkblue;
    }

    // Lighter 
    &.--light {
        color: $brand-lightblue;
    }

    // Pale 
    &.--pale {
        color: $brand-paleblue;
    }

    &.--hero,
    &.--hero p {
        color: #fff;
        font-weight: 600;
        font-size: 32px;
        line-height: 1.2;
    }

    &.--intro,
    &.--intro p {
        font-size: 18px;
        color: $brand-text;
        font-family: $font-sans-serif;
        font-weight: 400;
        line-height: 1.65;
    }

    // Media Queries
    @include media-query-blue {
        font-size: 18px;
    }
}