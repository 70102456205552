/// Footer

footer.primary {
    text-align: center;
    padding: ($gutter * 3.5) 0 ($gutter * 3.5) 0;

    .logo {
        margin-bottom: 40px;
        img {
            max-width: 100%;
        }
    }
    .email {
        margin-bottom: $gutter;
    }
    nav.utility {
        margin-bottom: $gutter * 2;
        ul {
            justify-content: center;
            padding-left: 0;
        }
        li {
            margin-left: 0;
            padding: 0 ($gutter * 1.5);
            position: relative;
            &::after {
                content: "|";
                position: absolute;
                right: 0;
                top: -4px;
                font-size: 1.7rem;
            }
            .nav-link {
                font-size: 18px;
            }
        }
        li:last-child::after {
            content: "";
        }

        &.-socialIcons {
            margin-bottom: $gutter;

            ul {
                border-bottom: 2px solid $brand-lightgray;
                width: 35%;
                margin: 0 auto;
                padding-bottom: 1.5rem;
            }

            li {
                padding: 0 10px;

                &::after {
                    content: none;
                }
            }
        }
    }
    @include media-query-blue {
        nav.utility {
            ul {
                flex-direction: column;
                align-items: center;
            }
            li {
                margin-bottom: $gutter;
            }
            li::after {
                display: none;
            }
        }
    }
}
