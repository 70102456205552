.two-column-brand {
    padding-bottom: 100px;
    padding-top: 50px;
    h2.--feature {
        margin-bottom: 50px;
    }
    .wrapper {
        display: flex;
        flex-direction: row;
        .branding,
        .content {
            width: 50%;
        }
        p {
            margin-bottom: $gutter * .5;
        }
    }
    .branding {
        position: relative;
        max-height: 1242px;
        svg {
            width: 130%;
            position: relative;
            z-index: 3;
        }
        .one {
            z-index: 2;
        }
        .two {
            z-index: 0;
        }
        .three {
            z-index: 1;
        }
        .one, .two, .three {
            position: absolute;
        }
    }
    &.--left {
        .compact-testimonial,
        .featured-resource {
            position: relative;
            margin-left: -100px;
            padding-left: $gutter;
            margin-top: 80px;
            margin-bottom: 80px;
            overflow: visible;
            .info {
                width: auto;
                flex-grow: 1;
            }
            .button {
                margin-right: 0;
            }
            .image {
                padding-top: 0;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: -2000px;
                width: 2000px;
                height: 100%;
                background: $brand-lightgray;
            }
        }
        .branding {
            svg {
                margin-left: -40%;
            }
            .one {
                left: 6%;
                top: 30%;
            }
            .two {
                left: -21%;
                top: 63%;
            }
            .three {
                left: -13%;
                top: 53%;
            }
            &.--short {
                .one {
                    left: -84%;
                    top: -10%;
                }
                .two {
                    left: -31%;
                    top: 59%;
                    transform: rotate(15deg);
                }
                .three {
                    left: -60%;
                    top: 33%;
                    transform: rotate(62deg);
                }
            }
        }
    }
    &.--right {
        .compact-testimonial,
        .featured-resource {
            position: relative;
            margin-right: -100px;
            text-align: right;
            padding-right: $gutter;
            margin-top: 80px;
            margin-bottom: 80px;
            overflow: visible;
            .info {
                width: auto;
                flex-grow: 1;
            }
            .button {
                margin-right: 0;
            }
            .image {
                padding-top: 0;
            }
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -2000px;
                width: 2000px;
                height: 100%;
                background: $brand-lightgray;
            }
        }
        .branding {
            svg {
                margin-left: $gutter * 2;
            }
            .one {
                right: calc(15% - 60px);
                transform: rotate(196deg);
                top: 33%;
            }
            .two {
                right: -25%;
                top: 61%;
                transform: rotate(78deg);
            }
            .three {
                right: calc(3% - 60px);
                top: 49%;
                transform: rotate(18deg);
            }
            &.--short {
                .one {
                    right: -74%;
                    transform: rotate(86deg);
                    top: -10%;
                }
                .two {
                    right: -35%;
                    top: 57%;
                    transform: rotate(58deg);
                }
                .three {
                    right: -52%;
                    top: 33%;
                    transform: rotate(-44deg);
                }
            }
        }
    }
    @include media-query-green {
        .wrapper {
            flex-direction: column;
            .branding,
            .content {
                width: 100%;
            }
            .branding {
                text-align: center;
                .one,.two,.three {
                    display: none;
                }
                svg {
                    margin-left: 0;
                    max-width: 300px;
                    height: 200px;
                }
            }
        }
        &.--right {
            .branding {
                order: 0;
            }
            .content {
                order: 1;
            }
            .compact-testimonial,
            .featured-resource {
                margin-right: -30px;
                text-align: left;       
                margin-top: 40px;
                margin-bottom: 40px;    
            }
        }
        &.--left {
            .compact-testimonial,
            .featured-resource {
                margin-left: -30px;    
                margin-top: 40px;
                margin-bottom: 40px;    
            }
        }
    }
}