/// About Preview

.about-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    h2 {
        width: 100%;
        max-width: 889px;
        margin-bottom: 30px;
    }
    .text {
        width: 58%;
        padding-left: 0;
        padding-right: 72px;
        p {
            margin-bottom: 40px;
        } 
    }
    .video {
        width: 42%;
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 396px;
            height: 401px;
            background: url('/img/icons/videotriangle.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
            top: -44px;
            right: -170px;
        }
        .video-embed {
            margin-bottom: $gutter;
        }
        .testimonial {
            padding-right: 57px;
        }
    }
    @include media-query-blue {
        flex-direction: column;
        h2 {
            margin-bottom: $gutter;
        }
        .text,
        .video {
            width: 100%;
            padding: 0;
        }
        .text {
            margin-bottom: $gutter * 2;
        }
        .video {
            .video-embed {
                iframe {
                    max-width: 100%;
                }
            }
        }
    }
}