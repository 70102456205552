/// Dropdown Menu
.dropdown {
    position: absolute;
    border-radius: 5px;
    top: 117px;
    margin: auto;
    background: #fff;
    box-shadow: 0px -1px 20px rgba($brand-darkblue, .2);
    opacity: 0;
    transition: top 250ms;
    pointer-events: none;
    .content {
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 40px;
        padding-bottom: 20px;
        justify-content: space-between;
        .subnav-link {
            margin-bottom: $gutter * .333;
            .link {
                margin-top: 0;
            }
        }
        .eyebrow {
            width: 100%;
            margin-bottom: 20px;
            font-weight: 900;
        }
    }
    .arrow {
        position: absolute;
        top: -14px;
        width: 0;
        height: 0;
        transition: right 250ms;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 14px solid #fff;
        filter: drop-shadow(0px -7px 5px rgba($brand-darkblue, .1));
    }
    .hitbox {
        width: 125px;
        height: 38px;
        position: absolute;
        top: -34px;
    }
}

nav.primary ul li.expertise-navigation {
    position: relative;
    .dropdown {
        left: 45px;
        top: 48px;
        width: max-content;
    }
    .arrow {
        left: 45px;
    }
    .hitbox {
        left: 0;
    }
}

.services-navigation {
    .dropdown {
        right: 285px;
    }
    .arrow {
        left: 75px;
    }
    .hitbox {
        left: 100px;
    }
}

.resources {
    .arrow {
        right: 314px;
    }
    .hitbox {
        right: 60px;
    }
}

.membership-navigation {
    .dropdown {
        right: 65px;
    }
    .arrow {
        right: 100px;
    }
    .hitbox {
        right: 60px;
    }
}
.library-navigation {
    .dropdown {
        right: -52px;
    }
    .arrow {
        right: 63px;
    }
    .hitbox {
        right: 20px;
    }
}

body.scrolled .dropdown {
    top: 82px;
    .hitbox {
        top: -24px;
    }
}
