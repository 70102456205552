.services-intro {
    margin-bottom: $gutter * 2;
    margin-top: $gutter * 2;
    h2 {
        margin-bottom: $gutter;
        max-width: 900px;
    }
}

body.service {
    .jump-nav {
        margin-top: 0;
    }
}