// Primary Navigation

nav.primary ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    max-width: 1000px;
    max-height: 100px;
    transition: max-height 250ms;
    padding-left: 0;
    li {
        flex-grow: 1;
        text-align: right;
        margin-bottom: 0;
        position: static;
        &:hover {
            .dropdown {
                opacity: 1;
                pointer-events: auto;
            }
            .nav-link.-dropdown {
                color: $brand-lightblue;
                &::after {
                    transform: rotate(90deg);
                }
            }
        }
        &::before {
            display: none;
        }
    }
}
