/// Meta

.meta {
    font-size: 14px;
    color: $brand-darkblue;
    font-weight: 700;
    letter-spacing: 1pt;

    &.--time {
        color: $brand-gray;
        text-transform: uppercase;
        font-weight: 800;
        &::before {
            @include fa-icon;
            @extend .fas;
            content: fa-content($fa-var-clock);
            font-weight: 800;
            margin-right: 5px;
            vertical-align: 0px;
        }
    }

    &.--headcount {
        color: $brand-gray;
        text-transform: uppercase;
        font-weight: 800;
        &::before {
            @include fa-icon;
            @extend .fas;
            content: fa-content($fa-var-user);
            font-weight: 800;
            margin-right: 8px;
            vertical-align: 2px;
        }
    }

    &.--date,
    &.--location {
        color: $brand-darkgray;
        text-transform: uppercase;
        font-weight: 800;
        &.--time {
            text-transform: none;
        }
        &::before {
            @include fa-icon;
            @extend .fas;
            font-weight: 800;
            margin-right: 8px;
            vertical-align: 0px;
            color: $brand-yellow;
        }
    }
    &.--date::before {
        content: fa-content($fa-var-calendar);
    }
    &.--location::before {
        content: fa-content($fa-var-map-marker-alt);
    }
}