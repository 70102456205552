.client-result {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    padding: 110px 0;
    .--callout,
    .--callout p {
        color: #fff;
    }
    .eyebrow.-brand,
    .--callout {
        margin-bottom: 80px;
    }
    .eyebrow.-brand {
        padding: 0;
    }
    .wrapper {
        max-width: 585px;
        position: relative;
        z-index: 2;
    }
    svg {
        position: absolute;
        overflow: hidden;
        opacity: .98;
        z-index: 1;
        width: 1470px;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 175px;
        background: linear-gradient(to bottom, transparent, #000);
        bottom: 0;
    }
    &::after {
        content: '';
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($brand-darkblue, .98);
    }
    &.left {
        .wrapper {
            padding-left: $gutter;
            margin-left: 150px;
        }
        svg {
            top: -329px;
            left: -217px;
        }
    }
    &.right {
        .wrapper {
            margin-right: 150px;
            margin-left: auto;
            text-align: right;
            padding-right: $gutter;
        }
        svg {
            top: -329px;
            right: -367px;
        }
    }
    @media all and (-ms-high-contrast:none) {
        svg {
            height: 160%;
        }
    }

    @include media-query-green {
        &.left {
            .wrapper {
                margin-left: 75px;
            }
        }
        &.right {
            .wrapper {
                margin-right: 75px;
            }
        }

    }

    @include media-query-blue {
        &::after {
            display: block;
        }
        svg {
            display: none;
        }
        
        &.left {
            .wrapper {
                margin-left: 0;
                padding: 0 $gutter;
            }
        }
        &.right {
            .wrapper {
                margin-right: 0;
                padding: 0 $gutter;
            }
        }

    }
}