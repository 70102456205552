body.press {
    .wrapper {
        position: relative;
        min-height: 500px;
    }
    main {
        padding-top: $gutter * 2;
    }

    .hide-overlay {
        content: '';
        display: flex;
        position: absolute;
        background: #fff;
        top: -20px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 30px);
        opacity: 0;
        pointer-events: none;
        transition: opacity 250ms;
        z-index: 1;
        justify-content: center;
        align-items: flex-start;
        img {
            animation: spin 4s linear infinite;
            width: 100px;
            margin-top: 100px;
        }
    }
    .hide-overlay.active {
        pointer-events: auto;
        opacity: 1;
    }
}