// Buttons

.button,
.hs-button {
    border: 0;
    background: none;
    inherit: none;
    padding: 0;
    box-sizing: content-box;
    border-radius: 5px;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    text-decoration: none;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $font-sans-serif;
    padding: 17px 38px;
    display: inline-block;
    font-weight: 700;
    color: $brand-darkblue;
    transition: background 250ms;
    outline: none;
    cursor: pointer;

    &.-outline {
        background: transparent;

        //Defaults
        border: 2px solid $brand-yellow;
        color: $brand-darkblue;

        &.--primary {
            border: 2px solid $brand-green;
            color: $brand-darkblue;
            &:hover {
                background: rgba($brand-yellow, 0.25);
            }
        }

        &.--secondary {
            border: 2px solid $brand-yellow;
            color: $brand-darkblue;
            &:hover {
                background: rgba($brand-yellow, 0.25);
            }
        }

        &.--light {
            border: 2px solid #fff;
            color: #fff;
            &:hover {
                background: #fff;
                color: $brand-darkblue;
            }
        }
    }

    &.-solid,
    &.primary {
        border: none;

        //Defaults
        background: $brand-lightblue;
        color: #fff;

        &.--primary,
        &.large {
            background: $brand-lightblue;
            color: #fff;
            &:hover {
                background: lighten($brand-lightblue, 10%);
            }
        }
        &.--secondary {
            background: $brand-green;
            color: #fff;
            &:hover {
                background: lighten($brand-green, 10%);
            }
        }
    }

    &.-mobile-trigger {
        padding: 6px;
        padding-top: 2px;
        margin-top: -2px;
        font-size: 1.3rem;
        margin-left: 20px;
    }

    &.-mobile-close {
        font-size: 1.9rem;
        padding: 0;
        line-height: 0;
    }
    &.-slider-left,
    &.-slider-right {
        font-size: 1.6rem;
        padding: 0;
    }

    &.-redactorButton {
        display: inline-block;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;
        padding: 10px 20px 9px 20px;
        cursor: pointer;
        outline: none;
        border: none;
        vertical-align: middle;
        text-decoration: none;
        zoom: 1;
        position: relative;
        color: white !important;
        border-radius: 2px;
        background-color: #34a688;
        /* background-image: linear-gradient(#f3f6f9, #eaf0f4); */
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.22);
        margin-right: 5px;
        margin-bottom: 4px;
        text-decoration: none !important;

        &:hover{ 
            outline: none;
            background-color: white !important;
            color: #34a688 !important;
            /* background: #449aef !important; */
        }
    }
}
button.button,
button.hs-button {
    padding: 19px 38px;
}
button.button.-outline {
    padding: 17px 38px;
}

// For centering Buttons
// Applies to wrapping div
//
// Example:
// <div class="button-wrapper">
//     <button class="button"></button>
// </div>
//

.button-wrapper {
    text-align: center;
}
