/// Featured Resource

.featured-resource {
    background: $brand-darkblue;
    overflow: hidden;

    .featured-resource-row {
        display: flex;
    }
    .info {
        width: 50%;
        padding-top: $gutter * 2.5;
        padding-bottom: $gutter * 2;
    }
    .image {
        width: calc(50% - 90px);
        margin-right: $gutter * 3;
        margin-top: -($gutter * 2);
        margin-bottom: -($gutter * 2);
        padding: ($gutter * 4) 0;
        text-align: center;
        position: relative;
        z-index: 1;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 5000px;
            height: 100%;
            top: 0;
            left: -9999px;
            right: -9999px;
            pointer-events: none;
            margin: auto;
            background: url('/img/patterns/resourcetriangle.png');
            background-repeat: no-repeat;
            background-size: 740px;
            background-position: left 47% top -82px;
            z-index: 0;
        }
        img {
            box-shadow: 3px 3px 5px rgba(#000, .2);
            position: relative;
            z-index: 1;
            max-width: 250px;
        }
    }
    .eyebrow {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1;
    }
    p {
        color: #fff;
        font-size: 1.2rem;
        line-height: 2;
        margin-bottom: $gutter;
    }
    .button {
        margin-right: 25px;
        margin-bottom: $gutter * .5;
    }
    h3 {
        color: #fff;
        margin-top: 0;
        font-weight: 400;
    }

    &.--compact {
        padding: 25px 0;
        background: $brand-lightgray;
        .featured-resource-row {
            align-items: center;
        }
        h3 {
            color: $brand-darkblue;
            font-family: $font-sans-serif;
            font-size: 20px;
            font-weight: 800;
            margin-bottom: $gutter;
        }
        p {
            color: #000;
            line-height: 1.5;
            margin-top: -20px;
        }
        .image {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            width: auto;
            margin-right: 50px;
            img {
                max-width: 150px;
            }
            &::after {
                display: none;
            }
        }
        .info {
            padding: 0;
        }
    }

    &.--light {
        background: $brand-paleblue;
        h3 {
            color: $brand-darkblue;
        }
        p {
            color: #000;
        }
        .button.-outline {
            border-color: $brand-darkblue;
            color: $brand-darkblue;
            &:hover {
                color: #fff;
                background: $brand-darkblue;
            }
        }
    }

    @include media-query-green {
        .featured-resource-row {
            flex-direction: column;
            .info {
                width: 100%;
                padding-top: $gutter;
            }
            .image {
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0;
                padding-top: $gutter * 2.5;
                &::after {
                    display: none;
                }
            }
        }
    }
}