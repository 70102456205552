.team-group {
    margin-bottom: $gutter;
    .--feature {
        margin-bottom: 40px;
    }
    .intro {
        margin-bottom: 80px;
    }
    &.--diff-crop {
        .avatar img {
            min-width: 0;
            min-height: 0;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: auto;
        }
    }
}