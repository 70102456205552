/// TOC 
///
/// 1. Media Queries
///

@mixin media-query-red {     @media only screen and (max-width: 1400px) { @content; }}
@mixin media-query-yellow {  @media only screen and (max-width: 1200px) { @content; }}
@mixin media-query-green {   @media only screen and (max-width: 1030px) { @content; }}
@mixin media-query-blue {    @media only screen and (max-width: 770px) { @content; }}
@mixin media-query-purple {  @media only screen and (max-width: 500px) { @content; }}
@mixin media-query-black {   @media only screen and (max-width: 400px) { @content; }}
@mixin media-query-smallest {   @media only screen and (max-width: 350px) { @content; }}

#responsive-tester {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10000;
    padding: 5px;
    width: auto;
    background-color: #999;
    color: white;
    font-size: .6em;
    line-height: 1.0;
    opacity: .5;
    display: block;
}

@include media-query-red { #responsive-tester { background-color: $mq-red; } }
@include media-query-yellow { #responsive-tester { background-color: $mq-yellow; } }
@include media-query-green { #responsive-tester { background-color: $mq-green; } }
@include media-query-blue { #responsive-tester { background-color: $mq-blue; } }
@include media-query-purple { #responsive-tester { background-color: $mq-purple; } }
@include media-query-black { #responsive-tester { background-color: $mq-black; } }
@include media-query-smallest { #responsive-tester { background-color: #f00; } }