section.search {
    padding-top: $gutter * 5;
    padding-bottom: 2em;
}

form.search {
    display: flex;
    flex-direction: row;
    @include media-query-blue {
        flex-wrap: wrap;
    }

    .text {
        flex-grow: 1;
        margin-right: 30px;
        @include media-query-blue {
            flex-basis: 100%;
            margin: 0 0 1em 0;
        }
    }

    input.search{
        max-height: 47px;
        padding: 13px 26px;
    }

    &.head-search {
        .text {
            margin-right: 5px;
        }
        .submit {
            input {
                padding: .8em 1em;
            }
        }
        input.search {
            max-height: none;
            padding: .25em .5em;
        }
    }
}

section.search__results {

    article {
        padding: 1.5em 0;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    ul {
        li {
            display: inline-block;
            margin: 0 .25em;
            &::before {
                content: none;
            }
    
            &.current {
                color: $brand-lightblue;
                text-decoration: underline;
            }
        }
    }

    h2 {
        line-height: .75;
        margin-bottom: .35em;
        a {
            font-size: .5em;
        }
    }
}

.nav-search {
    position: relative;
    width: 225px;
    transition: all .5s ease;

    &.close {
        width: 0px;
        margin-left: 0;
        overflow: hidden;
    }
}