.featured-event {
    .columns.two-columns {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        & > div {
            width: 50%;
        }
    }
    .content p {
        margin-bottom: $gutter * .5;
    }
    .content {
        margin-bottom: 50px;
    }
    .image {
        padding-top: 110px;
        position: relative;
        margin-bottom: 140px;
        & > img {
            width: 100%;
            position: relative;
            z-index: 1;
            max-width: 100%;
        }
    }
    .branding {
        position: absolute;
        bottom: -150px;
        z-index: 0;
        img {
            width: 380px;
        }
    }
    h2 {
        font-size: 28px;
        margin-bottom: 30px;
    }
    .meta > * {
        margin-right: 40px;
        &:last-child {
            margin-right: 0;
        }
    }
    div.meta {
        margin-bottom: 25px;
    }
    &.--left {
        .image {
            padding-right: 110px;
        }
        .branding {
            left: -90px;
            transform: scaleX(-1);
        }
    }
    &.--right {
        .image {
            padding-left: 110px;
        }
        .branding {
            right: -90px;
        }
    }
    &.no-image {
        .columns.two-columns .info {
            width: 100%;
        }
    }
    @include media-query-green {
        .columns.two-columns {
            flex-direction: column;
            & > div {
                width: 100%;
            }
        }
        .branding {
            display: none;
        }
        .image {
            margin-bottom: 0;
            padding-top: 0;
        }
        .image > img {
            max-height: 450px;
            width: auto;
            margin-bottom: $gutter;
        }
        &.--left {
            .image {
                padding-right: 0;
            }
        }
        &.--right {
            .info {
                order: 1;
            }
            .image {
                padding-left: 0;
                order: 0;
            }
        }
    }
}