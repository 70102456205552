/// Nav Links

.nav-link {
    text-decoration: none;
    font-size: 16px;
    color: $brand-darkblue;
    font-family: $font-sans-serif;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;


    // Utility
    &.-utility {
        font-size: 14px;
        font-weight: 400;
    }
    &:hover {
        color: $brand-lightblue;
    }

    &.active {
        color: $brand-lightblue;
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            border-top: 2px solid $brand-lightblue;
            left: 0;
            bottom: -6px;
            right: 0;
        }
    }

    //Subnav Item
    &.-subnav {
        font-size: 20px;
        font-weight: 800;
    }

    //Lighter Weight
    &.-light {
        font-weight: 300;
    }

    &.-back {
        font-size: 1.6rem;
        background: none;
        border: none;
    }
}