
.contact-container {
	padding-top: $gutter * 3;
	padding-bottom: $gutter * 4;
	.contact-form {
		position: relative;
		z-index: 2;

		h2.--headline {
			color: $brand-paleblue;
		}
	}
	&.lazyloaded {
		background: url('/img/patterns/circlethree.png'), $brand-darkblue;
		background-position: right -623px top 91%;
		background-size: 1130px;
		background-repeat: no-repeat;
	}
	.hs-form {
		label {
			color: $brand-yellow;
		}
		.hs-button {
			background: $brand-green;
			color: #fff;
			&:hover {
				background: lighten($brand-green, 10%);
			}
		}
		.hs-submit {
			margin: 0;
		}
		.hs-error-msgs label {
			color: $brand-paleblue;
		}
	}
	.submitted-message p {
		color: $brand-paleblue;
	}
}

@include media-query-purple {
	.contact {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		.contact-form {
			margin-top: 0;
		}
	}
}

@include media-query-yellow {
	.contact.lazyloaded {
		background-position: right -306px top -16%;
		background-size: 540px;
	}
}

@include media-query-green {
	.contact.lazyloaded {
		background-image: none;
	}
}
