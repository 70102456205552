.pricing-table {
    padding: 100px 0;
    text-align: center;

    // Default Configuration
    &.style-default {
        background: $brand-lightgray;

        .wrapper {
            max-width: 790px;
            margin: 0 auto;
        }
    }

    h3 {
        padding: 2em 0;
    }

    .wrapper {
        h1 {
            color: $brand-darkblue;
            // font-size: 38px;
            margin-bottom: 40px;
        }
        p {
            margin-bottom: 40px;
        }
    }
}

// Content-Designer Reset
.content-designer .pricing-table .wrapper h3 {
    margin-top: 0;
}

.content-designer .pricing-table .wrapper p {
    margin-bottom: 40px;
}

.content-designer .pricing-table .wrapper p.button-container {
    margin-bottom: 0;
}