.testimonial-slider {
    padding: 100px 0;
    .wrapper {
        padding: 0 70px;
        position: relative;
        margin-bottom: $gutter * 2;
        .slide {
            display: flex;
            flex-direction: row;
        }
        .testimonial-video {
            flex-grow: 1;
            padding-right: 100px;
        }
        .video {
            display: block;
            width: 40%;
            min-width: 40%;
            .wrap {
                overflow: hidden;
                position: relative;
                min-height: 300px;
                & > * {
                    min-width: 100%;
                    min-height: 100%;
                    width: 100%;
                    position: absolute;
                    display: block;
                    top: -9999px;
                    right: -9999px;
                    bottom: -9999px;
                    left: -9999px;
                    margin: auto;
                }
            }
        }
        .button.-slider-right,
        .button.-slider-left {
            position: absolute;
            font-size: 56px;
            color: $brand-green;
            top: 50%;
            transform: translateY(-50%);
        }
        .button.-slider-left {
            left: 0;
        }
        .button.-slider-right {
            right: 0;
        }
    }

    &.--shade {
        background: $brand-paleblue;
    }
    @include media-query-green {
        padding: 50px 0;
        .wrapper {
            padding: 0;
            .slide {
                flex-direction: column;
            }
            .testimonial-video,
            .video {
                width: 100%;
            }
            .testimonial-video {
                padding-right: 0;
                margin-bottom: $gutter;
                blockquote {
                    margin-bottom: $gutter * .5;
                }
            }
            .video {
                height: 300px;
            }
            .button.-slider-right,
            .button.-slider-left {
                display: none;
            }
        }
    }
}